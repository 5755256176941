import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { MdArrowLeft, MdSettings, MdStar } from "react-icons/md";
import { HiArrowLeft } from "react-icons/hi";
import { CiEdit } from "react-icons/ci";
import { useDispatch, useSelector } from 'react-redux';
import JobService from '../../services/JobService';
import { NotificationManager } from "react-notifications";
import PriceInputModal from '../../components/PriceInputModal';
import { getHeroActiveBid } from '../../redux/actions/app';
import Avatar from '../../components/Avatar';
import GoogleMap from '../../components/Maps/GoogleMap';

const JobDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { job_id } = useParams();
    const { state } = useLocation();
    const [jobData, setJobData] = useState(state.job);
    const { user } = useSelector(state => state.auth)
    const [loading, setLoading] = useState(false);
    const [showPriceModal, setShowPriceModal] = useState(false);
    const [curLoc, setCurLoc] = useState({});

    useEffect(() => {
        updateSeenJob();
        setJobData(state.job);
    }, [state])

    useEffect(() => {
        setCurLoc({
            latitude: user?.cur_latitude,
            longitude: user?.cur_longitude,
            country: user?.address?.country,
            city: user?.address?.city,
            address_line1: user?.address?.address_line1,
        })
    }, [user])
 
    const updateSeenJob = () => {
        JobService.makeHeroSeenJob({
            job_id: job_id,
        }).then((res) => {
        }).catch(e => {
            console.log(e)
        });
    }

    const onPlaceBid = (price) => {
        setLoading(true);
        JobService.placeBid({
            job_id: job_id,
            price: price,
            currency: 'rm',
        }).then((res) => {
            console.log('placeBid ', res)
            setLoading(false);
            dispatch(getHeroActiveBid());
            navigate(-1);
        }).catch(e => {
            console.log(e)
            setLoading(false);
            NotificationManager.error(
                e?.message ||
                "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                "Error",
                3000
            );
        });
    }

    const offerPrices = useMemo(() => {
        let prices = [];
        let diff = parseInt(parseInt(jobData?.price) / 3);
        for (let i = 1; i <= 3; i++) {
            prices.push(parseInt(jobData?.price) + diff * i);
        }
        return prices;
    }, [jobData])
 
    return (
        <div className='w-full h-full bg-white-primary1 flex flex-col justify-center items-center'>
            <div className='w-full flex-1 relative'>
                <GoogleMap 
                    center={curLoc}
                    user={jobData?.customer}
                />                 
                <div className='w-[38px] h-[38px] rounded-full absolute top-7 left-6 drop-shadow bg-white-primary1 flex justify-center items-center'
                    onClick={() => navigate(-1)}
                >
                    <HiArrowLeft fontSize={24} className="ml-1 text-black2-gray3 " />
                </div>
            </div>
            <div className='w-full rounded-tl-[10px] rounded-tr-[10px] bg-white-primary1 drop-shadow'>
                <div className="w-full flex p-3 justify-center items-start rounded-tl-[10px] rounded-tr-[10px] bg-white-primary1"  >
                    <div className="mr-4 flex flex-col justify-center items-center">
                        <Avatar image={jobData?.customer?.photo} />
                        <p className="mt-1 text-[12px] text-primary1-white font-bold">{jobData?.customer?.name}</p>
                        <div className="flex justify-center items-center">
                            <MdStar color="#ffff00" size={13} />
                            <span className="text-[11px] text-primary1-white font-semibold">{parseFloat(jobData?.customer?.rating).toFixed(1)} ({jobData?.customer?.rating_cnt})</span>
                        </div>
                    </div>
                    <div className="h-full flex flex-1 flex-col justify-start items-start">
                        <p className="w-full text-[15px] leading-[21px] text-black2-gray3 ">{jobData?.address_line1} {jobData?.city} {jobData?.country}</p>
                        <p className="mt-1 w-full text-[18px] leading-[24px] text-primary1-white font-bold">{jobData?.title}</p>
                    </div>
                </div>
                <div className='w-full p-4 flex flex-col justify-center items-center'>
                    <button className='w-full h-[42px] rounded-[8px] bg-primary2 text-white text-[14px] font-semibold'
                        onClick={() => onPlaceBid(parseInt(jobData?.price))}
                    >
                        Accept RM {parseInt(jobData?.price)}
                    </button>
                    <p className='mt-5 text-center text-[15px] text-black2-gray3 font-semibold'>or Offer your price</p>
                    <div className='w-full mt-5 flex gap-3 justify-center items-center'>
                        {
                            offerPrices.map(p =>
                                <button key={p} className='flex-1 whitespace-nowrap h-[42px] rounded-[8px] bg-primary2 text-white text-[14px] font-semibold'
                                    onClick={() => onPlaceBid(p)}
                                >
                                    RM {p}
                                </button>
                            )
                        }
                        <button className=' w-[42px] h-[42px] rounded-[8px] flex justify-center items-center whitespace-nowrap bg-primary2 text-white text-[14px] font-semibold'
                            onClick={() => { setShowPriceModal(true) }}
                        >
                            <CiEdit fontSize={24} />
                        </button>
                    </div>
                </div>
            </div>
            {
                showPriceModal &&
                <PriceInputModal
                    showModal={showPriceModal}
                    onOk={(price) => {
                        setShowPriceModal(false);
                        onPlaceBid(price);
                    }}
                    onClose={() => setShowPriceModal(false)}
                />
            }

        </div>
    )
}

export default JobDetails;