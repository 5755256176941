import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import JobService from '../../../services/JobService';
import { NotificationManager } from "react-notifications";
import CancelJobModal from '../../../components/CancelJobModal';
import { isEmpty } from '../../../utils/common';
import HeroMap from '../../../components/Maps/HeroMap';
import Panel from './panel';


const HeroJobHome = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { job_id } = useParams();
    const [showCancelModal, setShowCancelModal] = useState(false);

    const handleCancelJob = (reason) => {
        JobService.cancelJob({
            job_id: job_id,
            reason: reason
        }).then((res) => {
            console.log('cancel job ', res)
            navigate('/customer');
        }).catch(e => {
            console.log(e)
            NotificationManager.error(
                e?.message ||
                "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                "Error",
                3000
            );
        });
    }

    return (
        <div className='w-full h-full  flex flex-col justify-center items-center'>
            <div className='flex justify-start items-center h-[50px] w-full bg-primary1 px-4'>
                <div className='text-[16px] text-white font-semibold cursor-pointer'
                    onClick={() => setShowCancelModal(true)}
                >
                    Cancel
                </div>
            </div>
            <HeroMap />
            <Panel />
            {
                showCancelModal &&
                <CancelJobModal
                    showModal={showCancelModal}
                    onClose={(data) => {
                        setShowCancelModal(false);
                        if (!isEmpty(data)) {
                            handleCancelJob(data);
                        }
                    }}
                />
            }
        </div>
    )
}

export default HeroJobHome;