import { SET_TRACK_DATA } from "../actions/types";

const initialState = {
  curStep: null,
  orientation: null,
  distance: null,
  nextStep: null,
  estTime: null,
  estDist: null,
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRACK_DATA:
      return {
        ...state,
        curStep: action.payload?.curStep,
        orientation: action.payload?.orientation,
        distance: action.payload?.distance,
        nextStep: action.payload?.nextStep,
        estTime: action.payload?.estTime,
        estDist: action.payload?.estDist,
      };
    default:
      return state;
  }
};

export default app;
