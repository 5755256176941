import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaListUl, FaMoneyBill, FaWallet } from "react-icons/fa";
import { MdOutlineSupportAgent } from "react-icons/md";
import SupportModal from "../../components/SupportModal";
import { useSelector } from "react-redux";

const HeroBottomTabs = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { newJobsCnt } = useSelector(state => state.app)
    const [activeBtn, setActiveBtn] = useState('offer');
    const [showSupportModal, setShowSupportModal] = useState(false);
    const btns = [
        { id: 'jobs', label: 'Job Offer', icon: (active) => <FaListUl size={22} className={active ? 'text-primary1-white' : 'text-black3-gray1'} /> },
        { id: 'income', label: 'Income', icon: (active) => <FaMoneyBill size={22} className={active ? 'text-primary1-white' : 'text-black3-gray1'} /> },
        { id: 'wallet', label: 'Wallet', icon: (active) => <FaWallet size={22} className={active ? 'text-primary1-white' : 'text-black3-gray1'} /> },
        { id: 'support', label: 'Support', icon: (active) => <MdOutlineSupportAgent size={22} className={active ? 'text-primary1-white' : 'text-black3-gray1'} /> },
    ]

    useEffect(() => {
        if (pathname.includes('hero/jobs')) {
            setActiveBtn('jobs')
        }
        if (pathname.includes('hero/income')) {
            setActiveBtn('income')
        }
        if (pathname.includes('hero/wallet')) {
            setActiveBtn('wallet')
        }
        if (pathname.includes('hero/suport')) {
            setActiveBtn('suport')
        }
    }, [pathname])

    const onClickBtn = (btn) => {
        if (btn.id != 'support') {
            navigate(`/hero/${btn.id}`);
        }
        else {
            setShowSupportModal(true);
        }
    }

    return (
        <div className="w-full flex justify-between items-center px-4 py-3 bg-white-primary1 drop-shadow">
            {
                btns.map(btn =>
                    <div key={btn.label} className=" cursor-pointer flex flex-col justify-center items-center relative"
                        onClick={() => onClickBtn(btn)}
                    >
                        {btn.icon(activeBtn == btn.id)}
                        <p className={`mt-1 font-semibold text-[14px] ${activeBtn == btn.id ? 'text-primary1-white ' : 'text-black3-gray1'}`}>{btn.label}</p>
                        {
                            (btn.id == 'jobs') && (newJobsCnt > 0) &&
                            <div className="absolute top-0 right-0 min-w-[18px] flex justify-center items-center p-1 rounded-full bg-[#f00] text-white text-[11px] leading-[11px] font-semibold">
                                {newJobsCnt}
                            </div>
                        }
                    </div>
                )
            }
            {
                showSupportModal &&
                <SupportModal
                    showModal={showSupportModal}
                    onClose={() => setShowSupportModal(false)}
                />
            }
        </div>
    );
};

export default HeroBottomTabs;


