import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

const AreYouSureModal = ({ showModal, title, desc, onYes, onCancel }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(showModal);
  }, [showModal])


  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      closeIcon={null}
      centered
      width={360}
      className="w-full  rounded-[24px]"
      title={title}
    >
      <div className="">
        <p className="mt-4 text-[15px] text-[#000] font-semibold text-center">{desc}</p>
        <div className="mt-5 w-full flex justify-center items-center gap-6">
          <Button className="h-[42px] px-6 rounded-full bg-[#333] text-white" onClick={onCancel}>
            Cancel
          </Button>
          <Button className="h-[42px] px-6 rounded-full bg-primary2 text-white"  onClick={onYes}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AreYouSureModal;


