import { DEFAULT_LANG, GOOGLE_MAP_API_KEY } from "../constants/config";
import { setDefaults, fromLatLng as geocodeFromLatLng } from "react-geocode";
import { extractCityInfo } from "./common";
import { getLanguage } from "./storage";

export const NO_PERMISSION = "NO_PERMISSION";
export const DISTANCE_UNIT = 10; // 10 m

export const getCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (error) => {
          reject(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 15000,
          maximumAge: 0,
        }
      );
    } else {
      reject("Not available");
    }
  });
};

export const getAddressByCoordinates = ({ latitude, longitude }) => {
  return new Promise((resolve, reject) => {
    setDefaults({
      key: GOOGLE_MAP_API_KEY, // Your API key here.
      language: getLanguage() || DEFAULT_LANG, // Default language for responses.
    });

    geocodeFromLatLng(latitude, longitude).then(
      (response) => {
        let data = extractCityInfo(response.results[0].address_components);

        resolve({
          latitude: latitude,
          longitude: longitude,
          address_line1: data.address_line1,
          address_line2: data.address_line2,
          country: data.country,
          city: data.city,
        });
      },
      (error) => {
        console.error("Geocode.fromLatLng", error);
        reject(error);
      }
    );
  });
};

export const fetchDirections = (
  directionsService,
  origin,
  dest,
  travelMode
) => {
  return new Promise((resolve, reject) => {
    if (directionsService != null) {
      directionsService.route(
        {
          origin: { lat: origin.lat, lng: origin.lng },
          destination: { lat: dest.lat, lng: dest.lng },
          travelMode: travelMode,
        },
        (result, status) => {
          if (status == "OK") {
            resolve(result);
          } else {
            reject(result);
          }
        }
      );
    } else {
      reject("directionsService is null");
    }
  });
};

export const getRouteData = (routeData) => {
  if (routeData == null) {
    return null;
  }
  var routeStepsData = routeData["routes"][0]["legs"][0]["steps"];
  let stepsData = [];
  let full_paths = [];
  for (let i = 0; i < routeStepsData.length; i++) {
    const steps = routeStepsData[i];
    let latlngslist = [];
    steps["lat_lngs"].forEach((coord) => {
      latlngslist.push({ lat: coord.lat(), lng: coord.lng() });
    });

    let new_latlngslist = interpolatePoints(latlngslist, DISTANCE_UNIT);

    stepsData.push({
      start_point: {
        lat: steps["start_point"].lat(),
        lng: steps["start_point"].lng(),
      },
      end_point: {
        lat: steps["end_point"].lat(),
        lng: steps["end_point"].lng(),
      },
      lat_lngs: latlngslist,
      new_latlngslist: new_latlngslist,
      instructions: steps["instructions"],
      distance: steps["distance"],
      duration: steps["duration"],
    });
    full_paths = [...full_paths, ...new_latlngslist];
  }

  return {
    full_paths,
    stepsData,
  };
};

export const findNearestLatLng = (latlng_coords, user_coord) => {
  // Function to calculate the Euclidean distance
  const euclideanDistance = (coord1, coord2) => {
    return Math.sqrt(
      Math.pow(coord1.lat - coord2.lat, 2) +
        Math.pow(coord1.lng - coord2.lng, 2)
    );
  };

  let nearestCoord = null;
  let nearestDistance = Infinity;

  if (latlng_coords == null) {
    return null;
  }
  latlng_coords.forEach((coord) => {
    const distance = euclideanDistance(coord, user_coord);
    if (distance < nearestDistance) {
      nearestDistance = distance;
      nearestCoord = coord;
    }
  });

  return nearestCoord;
};

export const getNewPaths = (paths, user_coord) => {
  let nearestCoord = findNearestLatLng(paths, user_coord);
  if (nearestCoord == null) {
    return [];
  }

  let newPaths = [user_coord];
  let foundIndex = paths.findIndex(
    (path) => path.lat == nearestCoord.lat && path.lng == nearestCoord.lng
  );
  if (foundIndex != -1) {
    newPaths = paths.slice(foundIndex, paths.length);
  }
  return newPaths;
};

export const getCurStepInfo = (stepsData, cur_coord) => {
  let curStep = null;
  let nextStep = null;
  let distance = null;

  let curStepIndex = 0;
  let estTime = 0;
  let estDist = 0;
  for (let i = 0; i < stepsData.length; i++) {
    if (stepsData[i].new_latlngslist) {
      const foundIdx = stepsData[i].new_latlngslist.findIndex(
        (item) => item.lat == cur_coord.lat && item.lng == cur_coord.lng
      );
      if (foundIdx != -1) {
        curStep = stepsData[i];
        if (i + 1 < stepsData.length) {
          nextStep = stepsData[i + 1];
        }
        distance = (curStep.new_latlngslist.length - (foundIdx + 1)) * DISTANCE_UNIT;
        curStepIndex = i;
        break;
      }
    }
  }
 
  for (let i = curStepIndex; i < stepsData.length; i++)
  {
    estTime = estTime + stepsData[i].duration.value;
    estDist = estDist + stepsData[i].distance.value;
  }
  
  return [curStep, nextStep, distance, estTime, estDist];
};

function calcDir(cur_bearing, next_bearing) {
  if (cur_bearing == null || next_bearing == null) {
    return "head";
  }

  if (Math.abs(cur_bearing - next_bearing) < 15) {
    return 'head';
  }

  if (cur_bearing >= 0 && cur_bearing < 180) {
    if (next_bearing >= 0 && next_bearing < cur_bearing) {
      return 'left';
    }
    else if (next_bearing >= cur_bearing && next_bearing < (cur_bearing + 180)) {
      return 'right';
    }
    else if (next_bearing >= (cur_bearing + 180) && next_bearing < 360) {
      return 'left';
    }
  }
  else if (cur_bearing >= 180 && cur_bearing < 360)
  {
    if (next_bearing >= cur_bearing && next_bearing < 360) {
      return 'right';
    }
    else if (next_bearing >= 0 && next_bearing < (cur_bearing - 180)) {
      return 'right';
    }
    else if (next_bearing >= (cur_bearing - 180) && next_bearing < cur_bearing) {
      return 'left';
    }
  }
   
  return "head";
}

export const getDirection = (curStep, nextStep) => {
  let direction = "head";
  if (curStep != null && nextStep != null) {
    let cur_bearing = null;
    let next_bearing = null;

    const tmpCurLen = curStep.lat_lngs?.length;
    if (tmpCurLen > 1) {
      cur_bearing = calculateBearing(
        curStep.lat_lngs[tmpCurLen - 2].lat,
        curStep.lat_lngs[tmpCurLen - 2].lng,
        curStep.lat_lngs[tmpCurLen - 1].lat,
        curStep.lat_lngs[tmpCurLen - 1].lng
      );
    }

    const tmpNextLen = nextStep.lat_lngs?.length;
    if (tmpNextLen > 1) {
      next_bearing = calculateBearing(
        nextStep.lat_lngs[0].lat,
        nextStep.lat_lngs[0].lng,
        nextStep.lat_lngs[1].lat,
        nextStep.lat_lngs[1].lng
      );
    }

    direction = calcDir(cur_bearing, next_bearing);
  }

  return direction;
};

// Interpolate points between waypoints
export const interpolatePoints = (points, meters) => {
  const unitDegree = 0.000009009;
  const interpolated = [];

  for (let i = 0; i < points.length - 1; i++) {
    const start = points[i];
    const end = points[i + 1];
    const distDegree = Math.sqrt(
      Math.pow(end.lat - start.lat, 2) + Math.pow(end.lng - start.lng, 2)
    );
    var numPoints = 1;
    if (distDegree > unitDegree * meters) {
      numPoints = Math.ceil(distDegree / (unitDegree * meters));
    }
    const latStep = (end.lat - start.lat) / numPoints;
    const lngStep = (end.lng - start.lng) / numPoints;
    for (let j = 0; j < numPoints; j++) {
      interpolated.push({
        lat: start.lat + latStep * j,
        lng: start.lng + lngStep * j,
      });
    }
  }

  return interpolated;
};

export const calculateBearing = (lat1, lon1, lat2, lon2) => {
  const toRadians = (degree) => (degree * Math.PI) / 180;
  const dLon = toRadians(lon2 - lon1);

  const y = Math.sin(dLon) * Math.cos(toRadians(lat2));
  const x =
    Math.cos(toRadians(lat1)) * Math.sin(toRadians(lat2)) -
    Math.sin(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.cos(dLon);

  const initialBearing = Math.atan2(y, x);
  const bearing = (initialBearing * 180) / Math.PI;

  return (bearing + 360) % 360;
};
