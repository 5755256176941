import React, { useEffect, useState } from "react";
import { GiCheckMark } from "react-icons/gi";
import LongPantBlack from '../../assets/images/clothes/Long-Pant-Black.png'
import LongPantBlue from '../../assets/images/clothes/Long-Pant-Blue.png'
import LongPantBrown from '../../assets/images/clothes/Long-Pant-Brown.png'
import LongPantCyan from '../../assets/images/clothes/Long-Pant-Cyan.png'
import LongPantGreen from '../../assets/images/clothes/Long-Pant-Green.png'
import LongPantGrey from '../../assets/images/clothes/Long-Pant-Grey.png'
import LongPantOrange from '../../assets/images/clothes/Long-Pant-Orange.png'
import LongPantPink from '../../assets/images/clothes/Long-Pant-Pink.png'
import LongPantPurple from '../../assets/images/clothes/Long-Pant-Purple.png'
import LongPantRed from '../../assets/images/clothes/Long-Pant-Red.png'
import LongPantWhite from '../../assets/images/clothes/Long-Pant-White.png'
import LongPantYellow from '../../assets/images/clothes/Long-Pant-Yellow.png'
import LongShirtBlack from '../../assets/images/clothes/Long-Shirt-Black.png'
import LongShirtBlue from '../../assets/images/clothes/Long-Shirt-Blue.png'
import LongShirtBrown from '../../assets/images/clothes/Long-Shirt-Brown.png'
import LongShirtCyan from '../../assets/images/clothes/Long-Shirt-Cyan.png'
import LongShirtGreen from '../../assets/images/clothes/Long-Shirt-Green.png'
import LongShirtGrey from '../../assets/images/clothes/Long-Shirt-Grey.png'
import LongShirtOrange from '../../assets/images/clothes/Long-Shirt-Orange.png'
import LongShirtPink from '../../assets/images/clothes/Long-Shirt-Pink.png'
import LongShirtPurple from '../../assets/images/clothes/Long-Shirt-Purple.png'
import LongShirtRed from '../../assets/images/clothes/Long-Shirt-Red.png'
import LongShirtWhite from '../../assets/images/clothes/Long-Shirt-White.png'
import LongShirtYellow from '../../assets/images/clothes/Long-Shirt-Yellow.png'
import LongSkirtBlack from '../../assets/images/clothes/Long-Skirt-Black.png'
import LongSkirtBlue from '../../assets/images/clothes/Long-Skirt-Blue.png'
import LongSkirtBrown from '../../assets/images/clothes/Long-Skirt-Brown.png'
import LongSkirtCyan from '../../assets/images/clothes/Long-Skirt-Cyan.png'
import LongSkirtGreen from '../../assets/images/clothes/Long-Skirt-Green.png'
import LongSkirtGrey from '../../assets/images/clothes/Long-Skirt-Grey.png'
import LongSkirtOrange from '../../assets/images/clothes/Long-Skirt-Orange.png'
import LongSkirtPink from '../../assets/images/clothes/Long-Skirt-Pink.png'
import LongSkirtPurple from '../../assets/images/clothes/Long-Skirt-Purple.png'
import LongSkirtRed from '../../assets/images/clothes/Long-Skirt-Red.png'
import LongSkirtWhite from '../../assets/images/clothes/Long-Skirt-White.png'
import LongSkirtYellow from '../../assets/images/clothes/Long-Skirt-Yellow.png'
import PantBlack from '../../assets/images/clothes/Pant-Black.png'
import PantBlue from '../../assets/images/clothes/Pant-Blue.png'
import PantBrown from '../../assets/images/clothes/Pant-Brown.png'
import PantCyan from '../../assets/images/clothes/Pant-Cyan.png'
import PantGreen from '../../assets/images/clothes/Pant-Green.png'
import PantGrey from '../../assets/images/clothes/Pant-Grey.png'
import PantOrange from '../../assets/images/clothes/Pant-Orange.png'
import PantPink from '../../assets/images/clothes/Pant-Pink.png'
import PantPurple from '../../assets/images/clothes/Pant-Purple.png'
import PantRed from '../../assets/images/clothes/Pant-Red.png'
import PantWhite from '../../assets/images/clothes/Pant-White.png'
import PantYellow from '../../assets/images/clothes/Pant-Yellow.png'
import ShirtBlack from '../../assets/images/clothes/Shirt-Black.png'
import ShirtBlue from '../../assets/images/clothes/Shirt-Blue.png'
import ShirtBrown from '../../assets/images/clothes/Shirt-Brown.png'
import ShirtCyan from '../../assets/images/clothes/Shirt-Cyan.png'
import ShirtGreen from '../../assets/images/clothes/Shirt-Green.png'
import ShirtGrey from '../../assets/images/clothes/Shirt-Grey.png'
import ShirtOrange from '../../assets/images/clothes/Shirt-Orange.png'
import ShirtPink from '../../assets/images/clothes/Shirt-Pink.png'
import ShirtPurple from '../../assets/images/clothes/Shirt-Purple.png'
import ShirtRed from '../../assets/images/clothes/Shirt-Red.png'
import ShirtWhite from '../../assets/images/clothes/Shirt-White.png'
import ShirtYellow from '../../assets/images/clothes/Shirt-Yellow.png'
import SingletBlack from '../../assets/images/clothes/Singlet-Black.png'
import SingletBlue from '../../assets/images/clothes/Singlet-Blue.png'
import SingletBrown from '../../assets/images/clothes/Singlet-Brown.png'
import SingletCyan from '../../assets/images/clothes/Singlet-Cyan.png'
import SingletGreen from '../../assets/images/clothes/Singlet-Green.png'
import SingletGrey from '../../assets/images/clothes/Singlet-Grey.png'
import SingletOrange from '../../assets/images/clothes/Singlet-Orange.png'
import SingletPink from '../../assets/images/clothes/Singlet-Pink.png'
import SingletPurple from '../../assets/images/clothes/Singlet-Purple.png'
import SingletRed from '../../assets/images/clothes/Singlet-Red.png'
import SingletWhite from '../../assets/images/clothes/Singlet-White.png'
import SingletYellow from '../../assets/images/clothes/Singlet-Yellow.png'
import SkirtBlack from '../../assets/images/clothes/Skirt-Black.png'
import SkirtBlue from '../../assets/images/clothes/Skirt-Blue.png'
import SkirtBrown from '../../assets/images/clothes/Skirt-Brown.png'
import SkirtCyan from '../../assets/images/clothes/Skirt-Cyan.png'
import SkirtGreen from '../../assets/images/clothes/Skirt-Green.png'
import SkirtGrey from '../../assets/images/clothes/Skirt-Grey.png'
import SkirtOrange from '../../assets/images/clothes/Skirt-Orange.png'
import SkirtPink from '../../assets/images/clothes/Skirt-Pink.png'
import SkirtPurple from '../../assets/images/clothes/Skirt-Purple.png'
import SkirtRed from '../../assets/images/clothes/Skirt-Red.png'
import SkirtWhite from '../../assets/images/clothes/Skirt-White.png'
import SkirtYellow from '../../assets/images/clothes/Skirt-Yellow.png'

export const Clothes = {
  LongPantBlack: { id: 'LongPantBlack', name: 'Black Long Pant', img: LongPantBlack },
  LongPantBlue: { id: 'LongPantBlue', name: 'Blue Long Pant', img: LongPantBlue },
  LongPantBrown: { id: 'LongPantBrown', name: 'Brown Long Pant', img: LongPantBrown },
  LongPantCyan: { id: 'LongPantCyan', name: 'Cyan Long Pant', img: LongPantCyan },
  LongPantGreen: { id: 'LongPantGreen', name: 'Green Long Pant', img: LongPantGreen },
  LongPantGrey: { id: 'LongPantGrey', name: 'Grey Long Pant', img: LongPantGrey },
  LongPantOrange: { id: 'LongPantOrange', name: 'Orange Long Pant', img: LongPantOrange },
  LongPantPink: { id: 'LongPantPink', name: 'Pink Long Pant', img: LongPantPink },
  LongPantPurple: { id: 'LongPantPurple', name: 'Purple Long Pant', img: LongPantPurple },
  LongPantRed: { id: 'LongPantRed', name: 'Red Long Pant', img: LongPantRed },
  LongPantWhite: { id: 'LongPantWhite', name: 'White Long Pant', img: LongPantWhite },
  LongPantYellow: { id: 'LongPantYellow', name: 'Yellow Long Pant', img: LongPantYellow },
  LongSkirtBlack: { id: 'LongSkirtBlack', name: 'Black Long Skirt', img: LongSkirtBlack },
  LongSkirtBlue: { id: 'LongSkirtBlue', name: 'Blue Long Skirt', img: LongSkirtBlue },
  LongSkirtBrown: { id: 'LongSkirtBrown', name: 'Brown Long Skirt', img: LongSkirtBrown },
  LongSkirtCyan: { id: 'LongSkirtCyan', name: 'Cyan Long Skirt', img: LongSkirtCyan },
  LongSkirtGreen: { id: 'LongSkirtGreen', name: 'Green Long Skirt', img: LongSkirtGreen },
  LongSkirtGrey: { id: 'LongSkirtGrey', name: 'Grey Long Skirt', img: LongSkirtGrey },
  LongSkirtOrange: { id: 'LongSkirtOrange', name: 'Orange Long Skirt', img: LongSkirtOrange },
  LongSkirtPink: { id: 'LongSkirtPink', name: 'Pink Long Skirt', img: LongSkirtPink },
  LongSkirtPurple: { id: 'LongSkirtPurple', name: 'Purple Long Skirt', img: LongSkirtPurple },
  LongSkirtRed: { id: 'LongSkirtRed', name: 'Red Long Skirt', img: LongSkirtRed },
  LongSkirtWhite: { id: 'LongSkirtWhite', name: 'White Long Skirt', img: LongSkirtWhite },
  LongSkirtYellow: { id: 'LongSkirtYellow', name: 'Yellow Long Skirt', img: LongSkirtYellow },
  LongShirtBlack: { id: 'LongShirtBlack', name: 'Black Long Shirt', img: LongShirtBlack },
  LongShirtBlue: { id: 'LongShirtBlue', name: 'Blue Long Shirt', img: LongShirtBlue },
  LongShirtBrown: { id: 'LongShirtBrown', name: 'Brown Long Shirt', img: LongShirtBrown },
  LongShirtCyan: { id: 'LongShirtCyan', name: 'Cyan Long Shirt', img: LongShirtCyan },
  LongShirtGreen: { id: 'LongShirtGreen', name: 'Green Long Shirt', img: LongShirtGreen },
  LongShirtGrey: { id: 'LongShirtGrey', name: 'Grey Long Shirt', img: LongShirtGrey },
  LongShirtOrange: { id: 'LongShirtOrange', name: 'Orange Long Shirt', img: LongShirtOrange },
  LongShirtPink: { id: 'LongShirtPink', name: 'Pink Long Shirt', img: LongShirtPink },
  LongShirtPurple: { id: 'LongShirtPurple', name: 'Purple Long Shirt', img: LongShirtPurple },
  LongShirtRed: { id: 'LongShirtRed', name: 'Red Long Shirt', img: LongShirtRed },
  LongShirtWhite: { id: 'LongShirtWhite', name: 'White Long Shirt', img: LongShirtWhite },
  LongShirtYellow: { id: 'LongShirtYellow', name: 'Yellow Long Shirt', img: LongShirtYellow },
  PantBlack: { id: 'PantBlack', name: 'Black Pant', img: PantBlack },
  PantBlue: { id: 'PantBlue', name: 'Blue Pant', img: PantBlue },
  PantBrown: { id: 'PantBrown', name: 'Brown Pant', img: PantBrown },
  PantCyan: { id: 'PantCyan', name: 'Cyan Pant', img: PantCyan },
  PantGreen: { id: 'PantGreen', name: 'Green Pant', img: PantGreen },
  PantGrey: { id: 'PantGrey', name: 'Grey Pant', img: PantGrey },
  PantOrange: { id: 'PantOrange', name: 'Orange Pant', img: PantOrange },
  PantPink: { id: 'PantPink', name: 'Pink Pant', img: PantPink },
  PantPurple: { id: 'PantPurple', name: 'Purple Pant', img: PantPurple },
  PantRed: { id: 'PantRed', name: 'Red Pant', img: PantRed },
  PantWhite: { id: 'PantWhite', name: 'White Pant', img: PantWhite },
  PantYellow: { id: 'PantYellow', name: 'Yellow Pant', img: PantYellow },
  ShirtBlack: { id: 'ShirtBlack', name: 'Black Shirt', img: ShirtBlack },
  ShirtBlue: { id: 'ShirtBlue', name: 'Blue Shirt', img: ShirtBlue },
  ShirtBrown: { id: 'ShirtBrown', name: 'Brown Shirt', img: ShirtBrown },
  ShirtCyan: { id: 'ShirtCyan', name: 'Cyan Shirt', img: ShirtCyan },
  ShirtGreen: { id: 'ShirtGreen', name: 'Green Shirt', img: ShirtGreen },
  ShirtGrey: { id: 'ShirtGrey', name: 'Grey Shirt', img: ShirtGrey },
  ShirtOrange: { id: 'ShirtOrange', name: 'Orange Shirt', img: ShirtOrange },
  ShirtPink: { id: 'ShirtPink', name: 'Pink Shirt', img: ShirtPink },
  ShirtPurple: { id: 'ShirtPurple', name: 'Purple Shirt', img: ShirtPurple },
  ShirtRed: { id: 'ShirtRed', name: 'Red Shirt', img: ShirtRed },
  ShirtWhite: { id: 'ShirtWhite', name: 'White Shirt', img: ShirtWhite },
  ShirtYellow: { id: 'ShirtYellow', name: 'Yellow Shirt', img: ShirtYellow },
  SingletBlack: { id: 'SingletBlack', name: 'Black Singlet', img: SingletBlack },
  SingletBlue: { id: 'SingletBlue', name: 'Blue Singlet', img: SingletBlue },
  SingletBrown: { id: 'SingletBrown', name: 'Brown Singlet', img: SingletBrown },
  SingletCyan: { id: 'SingletCyan', name: 'Cyan Singlet', img: SingletCyan },
  SingletGreen: { id: 'SingletGreen', name: 'Green Singlet', img: SingletGreen },
  SingletGrey: { id: 'SingletGrey', name: 'Grey Singlet', img: SingletGrey },
  SingletOrange: { id: 'SingletOrange', name: 'Orange Singlet', img: SingletOrange },
  SingletPink: { id: 'SingletPink', name: 'Pink Singlet', img: SingletPink },
  SingletPurple: { id: 'SingletPurple', name: 'Purple Singlet', img: SingletPurple },
  SingletRed: { id: 'SingletRed', name: 'Red Singlet', img: SingletRed },
  SingletWhite: { id: 'SingletWhite', name: 'White Singlet', img: SingletWhite },
  SingletYellow: { id: 'SingletYellow', name: 'Yellow Singlet', img: SingletYellow },
  SkirtBlack: { id: 'SkirtBlack', name: 'Black Skirt', img: SkirtBlack },
  SkirtBlue: { id: 'SkirtBlue', name: 'Blue Skirt', img: SkirtBlue },
  SkirtBrown: { id: 'SkirtBrown', name: 'Brown Skirt', img: SkirtBrown },
  SkirtCyan: { id: 'SkirtCyan', name: 'Cyan Skirt', img: SkirtCyan },
  SkirtGreen: { id: 'SkirtGreen', name: 'Green Skirt', img: SkirtGreen },
  SkirtGrey: { id: 'SkirtGrey', name: 'Grey Skirt', img: SkirtGrey },
  SkirtOrange: { id: 'SkirtOrange', name: 'Orange Skirt', img: SkirtOrange },
  SkirtPink: { id: 'SkirtPink', name: 'Pink Skirt', img: SkirtPink },
  SkirtPurple: { id: 'SkirtPurple', name: 'Purple Skirt', img: SkirtPurple },
  SkirtRed: { id: 'SkirtRed', name: 'Red Skirt', img: SkirtRed },
  SkirtWhite: { id: 'SkirtWhite', name: 'White Skirt', img: SkirtWhite },
  SkirtYellow: { id: 'SkirtYellow', name: 'Yellow Skirt', img: SkirtYellow },
}

const ClothItem = ({ name, color, topName, bottomName, type = 'single', isSelected, onClick }) => {
  if (type == 'single') {
    return (
      <div className='flex justify-center items-center relative' onClick={onClick}>
        <img src={Clothes[name + color].img} className="w-[56px] object-contain" />
        {
          isSelected &&
          <div className="flex justify-center items-center absolute top-0 right-0 p-1 bg-primary2 rounded-full">
            <GiCheckMark color="#fff" size={13} />
          </div>
        }
      </div>
    );
  }
  return (
    <div className='flex justify-start items-center' >
      <div className="flex flex-col justify-center items-center mr-4">
        <img src={Clothes[topName].img} className="h-[30px] object-contain" />
        <img src={Clothes[bottomName].img} className="h-[30px] object-contain" style={{marginTop: -4}}/>
      </div>
      <div>
        <p className="text-[14px] text-white">{Clothes[topName].name}</p>
        <p className="mt-1 text-[14px] text-white">{Clothes[bottomName].name}</p>
      </div>
    </div>
  );
};

export default ClothItem;


