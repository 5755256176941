import React, { useEffect } from 'react';
import ImgLogoDark from '../../assets/images/logo-black.jpg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './index.css';

const Splash = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <div className="w-full h-full max-w-md flex flex-col">
        <div className='w-full h-full p-10 bg-[#000] flex justify-center items-center'>
          <img src={ImgLogoDark} className="splash-logo w-[180px] object-contain" />
        </div>
      </div>
    </div>
  )
}

export default Splash;