import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft, FiChevronRight } from "react-icons/fi";
import UserService from "../../services/UserService";
import Avatar from "../../components/Avatar";
import { Rate } from "antd";
import moment from "moment";

const Rates = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);
    const [totalJobs, setTotalJobs] = useState(0);
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        UserService.getReviews()
            .then((res) => {
                setTotalJobs(res.totalJobs ?? 0);
                setReviews(res.reviews || []);
            }).catch(e => {
            });
    }

    return (
        <div className='w-full h-full  bg-white-primary1 flex flex-col justify-center items-center'>
            <div className="w-full flex justify-start items-center  py-4 bg-white-primary1 drop-shadow">
                <div className="w-[50px] flex justify-center items-center">
                    <FiArrowLeft size={20} className='text-primary1-white' onClick={() => navigate(-1)} />
                </div>
                <div className=" flex-1 text-center text-[16px] text-primary1-white font-bold ml-3">Reviews</div>
                <div className="w-[50px] flex justify-center items-center"></div>
            </div>
            <div className='my-5 flex-1 w-full flex flex-col px-5 justify-start items-center overflow-y-auto'>
                <div className="w-full flex justify-around items-center rounded-[8px] px-5 py-2 bg-gray5-primary2">
                    <div className="flex flex-col justify-center items-center">
                        <h3 className="text-[18px] text-primary1-white font-bold">{totalJobs}</h3>
                        <p className="text-[12px] text-primary1-white font-semibold">jobs</p>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <h3 className="text-[18px] text-primary1-white font-bold">{user.rating_cnt}</h3>
                        <p className="text-[12px] text-primary1-white font-semibold">reviews</p>
                    </div>
                </div>
                {
                    reviews.map((review, id) =>
                        <div key={id} className="w-full mt-4 flex justify-start items-start rounded-[8px] bg-gray5-primary2 p-2">
                            <div className="flex flex-col justify-center items-center w-[80px]">
                                <Avatar image={review.giver?.photo} className='!w-[36px] !h-[36px]'/>
                                <p className="mt-2 w-full text-[12px] leading-[16px] text-primary1-white text-center break-words">
                                    {(review.giver?.name ?? review.giver?.user_name)}
                                </p>
                            </div>
                            <div className="flex-1 ml-3">
                                <div className="flex justify-between items-center">
                                    <Rate
                                        disabled
                                        value={parseFloat(review.rating).toFixed(1)}
                                        style={{ fontSize: 15, }}
                                    />
                                    <p className="text-[13px] text-black2-gray3">
                                        {moment(review.created_at).format('DD.MM.YYYY')}
                                    </p>
                                </div>
                                <p
                                    className="mt-2 w-full text-[15px] font-semibold leading-[22px] text-primary1-white"
                                >{review.description}</p>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default Rates;
