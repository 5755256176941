import React, { useEffect, useState } from "react";
import { ClothColors } from "../../constants/config";

const ColorPickItem = ({ color, isSelected, onClick }) => {
  return (
    <div className={`w-[25px] h-[25px] ${isSelected ? 'border-[3px] border-primary2' : 'border-1 border-[#aaa]'}`}
      style={{ backgroundColor: ClothColors[color]}}
      onClick={onClick}
    >
    </div>
  );
};

export default ColorPickItem;


