import React, { useEffect, useMemo, useRef, useState } from "react";
import { formatDistance, isEmpty, removeHtmlTags } from "../../../utils/common";
import "./index.css";
import {
  TbCornerUpLeft,
  TbCornerUpRight,
  TbArrowNarrowUp,
} from "react-icons/tb";
import { MdClose } from "react-icons/md";
import useText2Speech from "../../../utils/text2speech";
import { useDispatch, useSelector } from "react-redux";
import { googleTextToSpeech } from "../../../utils/googleSpeech";
import { setTrackData } from "../../../redux/actions/track";

const MapInstruction = () => {
  // const speak = useText2Speech();
  const dispatch = useDispatch();
  const { curStep, nextStep, distance, orientation } = useSelector(
    (state) => state.track
  );
  const [direction, setDirection] = useState('head');

  const dirInstruction = useMemo(() => {
    if (distance <= 120) {
      if (orientation == "left" || orientation == "right") {
        return "Turn " + orientation;
      } else if (orientation == "head") {
        return nextStep?.instructions ?? "Go straight";
      }
    }
    return null;
  }, [nextStep?.instructions, distance, orientation]);

  useEffect(() => {
    if (curStep?.instructions) {
      const instruction = removeHtmlTags(
        dirInstruction != null ? dirInstruction : curStep?.instructions
      );
      const firstSlashIndex = instruction.indexOf("/");
      let refinedInstruction =
        `In ${formatDistance((distance - 20), true)}, ` +
        (firstSlashIndex !== -1
          ? instruction.substring(0, firstSlashIndex)
          : instruction);
  
      if (refinedInstruction) {
        // speak(refinedInstruction);
        // or
        googleTextToSpeech(refinedInstruction);
      }
    }

    let dirTxt = curStep?.instructions;
    if (dirInstruction) {
      dirTxt = dirInstruction;
    }

    if (!isEmpty(dirTxt)) {
      if ( dirTxt.toLowerCase().includes('left')) {
        setDirection('left');
      }
      else if ( dirTxt.toLowerCase().includes('right')) {
        setDirection('right');
      }
      else {
        setDirection('head');
      }
    }
   
  }, [dirInstruction, curStep?.instructions]);

  const onClose = () => {
    dispatch(setTrackData(null));
  };

  if (curStep == null) {
    return null;
  }

  return (
    <div className="w-full p-5 absolute top-0 left-0">
      <div className="w-full p-3 bg-primary1 rounded-2xl flex justify-center items-center relative">
        <div className="p-2 flex justify-center items-center">
          {direction == "left" ? (
            <TbCornerUpLeft className="text-white" size={32} />
          ) : direction == "right" ? (
            <TbCornerUpRight className="text-white" size={32} />
          ) : (
            <TbArrowNarrowUp className="text-white" size={32} />
          )}
        </div>
        <div className="ml-4 flex-1 flex flex-col justify-start items-start">
          <h2 className="text-white text-[18px] font-bold">
            {formatDistance(distance)}
          </h2>
          <div
            className="text-white text-[14px]"
            dangerouslySetInnerHTML={{
              __html: dirInstruction ?? curStep?.instructions,
            }}
          ></div>
        </div>
        <MdClose
          className=" cursor-pointer text-white absolute top-3 right-4"
          size={22}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  return true;
};

export default React.memo(MapInstruction, arePropsEqual);
