import apiFactory from '../../utils/apiFactory';
import { API_Auth, API_User } from '../../constants/apiConfig';
import { APP_SET_ACCESS_TOKEN, APP_SET_LOGOUT, APP_SET_USER_DATA } from './types';
import { clearAppToken, getAppToken, setAppToken } from '../../utils/storage';

export const loadLoginData = () => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        const token = getAppToken();
        if (token) {
            await dispatch({
                type: APP_SET_ACCESS_TOKEN, payload: token
            });
            apiFactory({ ...API_User, path: (API_User.path + '/profile'), method: 'get' }, {}, token)
                .then(async (data) => {
                    await dispatch({
                        type: APP_SET_USER_DATA, payload: data?.user
                    });
                    resolve(data?.user);
                })
                .catch(error => {
                    resolve(null);
                })
        }
        else {
            setTimeout(() => {
                resolve()
            }, 3000)
        }
    });
};

export const updateUserData = (payload) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_User, path: (API_User.path + '/update'), method: 'post' }, payload)
            .then(async (data) => {
                await dispatch({
                    type: APP_SET_USER_DATA, payload: data?.user
                });
                resolve(data?.user);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const updateUserAddress = (payload) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_User, path: (API_User.path + '/update-address'), method: 'post' }, payload)
            .then(async (data) => {
                await dispatch({
                    type: APP_SET_USER_DATA, payload: data?.user
                });
                resolve(data?.user);
            })
            .catch(error => {
                resolve(null);
            })
    });
};

export const setUserData = (token, user) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        setAppToken(token);
        await dispatch({
            type: APP_SET_ACCESS_TOKEN, payload: token
        });
        await dispatch({
            type: APP_SET_USER_DATA, payload: user
        });
        resolve();
    });
}

export const logout = () => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        clearAppToken();
        await dispatch({
            type: APP_SET_LOGOUT
        });
        resolve();
    });
};