import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { APP_SET_ENLARGED_IMAGE } from "../../redux/actions/types";
import { isEmpty } from "../../utils/common";
import ImgPerson from '../../assets/images/placeholder.jpg'

const Avatar = ({ image, className = '' }) => {
  const dispatch = useDispatch();

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isEmpty(image)) {
      dispatch({
        type: APP_SET_ENLARGED_IMAGE,
        payload: image
      })
    }
  }

  return (
    <img src={!isEmpty(image) ? image : ImgPerson} className={`w-[48px] h-[48px] rounded-full object-cover ${className}`}
      onClick={onClick}
    />
  );
};

export default Avatar;


