import React, { useEffect, useState } from "react";
import { Drawer, Spin, Rate } from 'antd';
import './index.css'
import JobService from "../../services/JobService";
import { NotificationManager } from "react-notifications";

const RateModal = ({ title, showModal, job, onClose }) => {
    const [open, setOpen] = useState(false);
    const [rate, setRate] = useState(0);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setOpen(showModal)
    }, [showModal])

    const onSubmit = () => {
        setLoading(true);
        JobService.leaveReview({
            job_id: job.id,
            rate: rate,
            message: message
        })
            .then((res) => {
                setLoading(false);
                onClose();
            }).catch(e => {
                console.log(e)
                setLoading(false);
                NotificationManager.error(
                    e?.message ||
                    "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                    "Error",
                    3000
                );
            });
    }

    return (
        <Drawer
            placement={'bottom'}
            closable={false}
            maskClosable={false}
            open={open}
            rootClassName="w-full max-w-md mx-auto"
            className="rate-modal"
            height={340}
        >
            <div className="bg-[#1c1f24] p-5">
                <div className="w-full flex justify-center items-center ">
                    <p className="flex-1 text-center text-white text-[20px] font-bold">
                        {title}
                    </p>
                </div>
                <div className="mt-6 w-full flex flex-col justify-center items-center">
                    <Rate value={rate} allowClear={false} onChange={setRate}
                        style={{ fontSize: 32, }}
                    />
                    <textarea
                        placeholder="Write a review"
                        className="p-3 w-full h-[120px] max-h-[150px] mt-4 text-white border-[2px] border-[#666] rounded-[10px] bg-[#323943]"
                        style={{ border: '2px solid white' }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    >
                    </textarea>
                </div>
                <div className="mt-4 w-full flex justify-center items-center">
                    {
                        loading ?
                            <div className='w-full h-[40px] flex justify-center items-center'>
                                <Spin />
                            </div>
                            :
                            <button className="text-[14px] text-white font-semibold rounded-[10px] bg-primary2 w-full h-[40px]"
                                onClick={onSubmit}>
                                Send
                            </button>
                    }
                </div>
            </div>
        </Drawer>
    );
};

export default RateModal;


