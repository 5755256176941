import apiFactory from "../utils/apiFactory";
import {
  API_Auth
} from "../constants/apiConfig";

export default class ConfigService {
  static getFaqs = async () => {
    let api = {
      content_type: "application/json",
      path: `${API_Auth.path}/faqs`,
      method: "GET",
    };
    return apiFactory(api);
  };
  static getConfig = async () => {
    let api = {
      content_type: "application/json",
      path: `${API_Auth.path}/config`,
      method: "GET",
    };
    return apiFactory(api);
  };
}
