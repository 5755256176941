import React from "react";
import { formatDistance, isEmpty, removeHtmlTags } from "../../../utils/common";
import { useDispatch, useSelector } from "react-redux";

const Estimation = () => {
  const { estTime, estDist } = useSelector(
    (state) => state.track
  );

  if (estTime == null || estDist == null || estTime == 0 || estDist == 0) {
    return null;
  }

  return (
    <div className="w-full absolute bottom-4 left-0 flex justify-center items-center">
      <div className="flex justify-center items-center py-1 px-6 rounded-full bg-white shadow-lg border border-gray-500">
        <h2 className="text-black text-[16px] font-bold">
          {estTime >= 60 ? Math.ceil(estTime / 60) + ' min' : estTime + ' s'}
          <span className="px-3 text-[22px]">&middot;</span>
          {formatDistance(estDist)}
        </h2> 
      </div>
    </div>
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  return true;
};

export default React.memo(Estimation, arePropsEqual);
