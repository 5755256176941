import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft, FiChevronRight } from "react-icons/fi";
import ConfigService from "../../services/ConfigService";

const Privacy = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState('');

    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        ConfigService.getConfig()
            .then((res) => {
                setData(res.data?.safety || '');
            }).catch(e => {
            });
    }
    return (
        <div className='w-full h-full  bg-white-primary1 flex flex-col justify-center items-center'>
            <div className="w-full flex justify-start items-center  py-4 bg-white-primary1 drop-shadow">
                <div className="w-[50px] flex justify-center items-center">
                    <FiArrowLeft size={20} className='text-primary1-white cursor-pointer' onClick={() => navigate(-1)} />
                </div>
                <div className=" flex-1 text-center text-[16px]  text-primary1-white font-bold ml-3">Privacy Policy</div>
                <div className="w-[50px] flex justify-center items-center"></div>
            </div>
            <div className='  flex-1 w-full flex flex-col px-5 justify-start items-center overflow-y-auto'>
                <p className="mt-5 w-full text-[14px] leading-[22px] text-black2-gray3">

                    Heromay is committed to protecting the personal information of its users. Please read this Privacy Policy (this “Policy”) carefully to understand how Heromay Enterprise (“Heromay”, “we”) processes personal information. This Policy describes how Heromay processes personal information of its users and other visitors browsing the Site (“users”) in connection with the Site and other services provided by Heromay as well as privacy practices that apply to affiliates, customers and freelancers.<br /><br />



                    By accessing or using www.Heromay.com, the Heromay mobile app, or any other related sites, applications, services and goods, or any other website operated by Heromay that links to this policy (each, the “Site”), as well as by registering to a Heromay account, you acknowledge that you have read and understood this Policy. This Policy may change from time to time; any changes we make to this Policy will be posted on this Site, we will also take any other steps, to the extent required by applicable law, including notifying you about material changes. Changes to this Policy are effective as of the stated "Last Updated" date. We recommend that you check the Policy periodically for any updates or changes.<br /><br />



                    <b>Information We Collect</b> – You directly provide us with most of the information we collect: when you register to the Site, use it, complete forms, or register to any of our programs. We also collect information about your communications with Heromay as well as any of your posts on our blogs or forums and your communication with other users of Heromay. In addition, we automatically collect information while you access, browse, view or otherwise use the Site and receive information from third party vendors or other available sources.<br /><br />



                    Our Legal Basis for Using Your Personal Information - Where relevant under applicable laws, all processing of your personal information will be justified by a "lawful ground" for processing as detailed below.<br /><br />



                    <b>How Do We Use the Information Collected?</b>  – We use personal information to provide you with quality service and security, to operate the Site, to perform our obligations to you and to develop, improve and personalize our service; to ensure marketplace integrity and security; to prevent fraud; to contact you and send you direct marketing communications; to promote and advertise the Site, our services and the Heromay marketplace; to comply with lawful requests by public authorities and to comply with applicable laws and regulations.<br /><br />



                    <b>How Long Do We Keep Personal Information</b> – We will keep personal information only for as long as is required to perform the purpose for which it was collected. However, in some cases we will retain personal information for longer periods of time.<br /><br />



                    <b>Children</b> - This Site is offered and available to users who are at least 18 years of age and of legal age to form a binding contract. Minors under 18 and at least 13 years of age, are only permitted to use the Site through an account owned by a parent or legal guardian with their appropriate permission. Minors under 13 are not permitted to use the Site or the Heromay services. We do not knowingly collect personal information from children under 13.<br /><br />



                    <b>Sharing Personal Information with Third Parties</b> – We share personal information with third parties in order to operate the Site, provide our services to you, perform obligations imposed on us by applicable laws and regulations, and prevent fraud, infringements and illegal activities.<br /><br />



                    <b>Where We Process Personal Information</b> - Some of the personal information you provide to us will be stored or processed on our behalf by third party suppliers and data processors and may be located in other jurisdictions, such as the United States and Israel.<br /><br />



                    <b>Cookies</b> - We use cookies and similar technologies (such as web beacons, pixels, tags, and scripts) to improve and personalize your experience, provide our services, analyze website performance and for marketing purposes.<br /><br />



                    <b>Do Not Track (DNT)</b> – Our Site does not respond to Do Not Track (DNT) signals.<br /><br />



                    <b>External Links</b> - the Site contains links to third party sites and if you link to a third party site from the Site, any data you provide to that site and any use of that data by the third party are not under the control of Heromay and are not subject to this Policy.<br /><br />



                    <b>Security</b> – We implement technical and organizational measures to maintain the security of the Site and your personal information and in preventing unauthorized access, loss, misuse, alteration, destruction or damage to it through industry standard technologies and internal procedures.<br /><br />



                    <b>Specific Provisions for EU, EEA and UK Users</b> – If you are an EU, EEA or UK user, you are entitled to receive further information on our processing and your rights as a data subject, as detailed below.<br /><br />



                    <b>Specific Provisions for US Residents</b> – If you are a US resident, depending on where you live, and subject to certain exceptions, you are entitled to specific privacy rights as detailed below.<br /><br />



                    <b>Updating Personal Information</b> – We take steps to ensure that the personal information we collect is accurate and up to date, and we provide you with the opportunity to update your information through your account profile settings. In the event that you believe your information is in any way incorrect or inaccurate, please let us know immediately.<br /><br />



                    <b>Contact Us</b> - You can exercise your rights over your personal information, by opening a Customer Relations ticket or contacting us at admin@heromay.com. For Heromay Workspace users, please contact us at workspace-support@heromay.com. More details about the rights applicable to you are in the long version of the Policy.<br /><br />



                    The above are just the highlights. We encourage you to read more about the information we collect, how we use it, understand the meaning of cookies (no, you can’t eat it) and more in the detailed version of our Policy below.<br /><br />


                    <h2 className="mt-1 mb-2 text-[18px] leading-[28px] font-bold  ">Information We Collect</h2>


                    <b>Information You Provide.</b> When you register to the Site, use it, complete forms, participate in skills tests or surveys, sign up for events or newsletters, use the Site as collaborator, and/or register to our affiliate or influencer or similar program, we ask you to provide or otherwise collect personal information provided by you, including a valid email address, login details, name and username, profile details (including picture, education, profession and expertise), physical address or billing information, telephone number or other contact details, date of birth, transactional information, payment information (for example, your payment method and credit card number), taxpayer information and forms (including W-9 Forms and DAC-7 Forms), details about other social networks linked accounts, details about your listed gigs, orders, purchases and transactions rating and reviews, information included in user generated content and files uploaded by you to the Site. We may also collect identification information (such as copies of your government issued ID, passport, or driving license) and biometric information derived from photos and identification documents you submit for verification, as further detailed in our Help Center at “Verifying Your Identity”). Business sellers on Heromay are also requested to provide additional information to verify their business (learn more on "Account Verification"). We also collect and process information about or contained in your communications with Heromay as well as any of your posts on our blogs or forums and your communication with other users of Heromay.<br /><br />



                    <b>Information We Collect Automatically.</b> We collect information while you access, browse, view or otherwise use the Site including information about transactions and communications with other users, your searches, the pages you visited, browsed and scrolled as well as your clicks and other actions on the Site. We also, collect, use and process the information relating to such usage, including geo-location information, IP address, device and connection information, operating system, session duration, language, currency and keyboard setting, browser information and web-log information, and the URLs of the web pages you’ve viewed or engaged with before or after using the Site. We also collect and process information relating to the use of cookies and similar technologies, as detailed below.<br /><br />



                    Additionally, in order to improve your online experience at Heromay, we have implemented impression reporting. While you view our ads, we gather user Global Unique Identifier, HTTP request data like, user agent, IP, host, URL, country/continent from which request made, browser info, device/operating system/operating system version.<br /><br />



                    <b>Information We Receive from Third Parties.</b> We receive information from third party vendors and/or other commercially and/or publicly available sources, such as:<br /><br />




                    <h2 className="mt-1 mb-2 text-[18px] leading-[28px] font-bold  ">Our Legal Basis for Using Your Personal Information</h2>


                    Where relevant under applicable laws, all processing of your personal information will be justified by a "lawful ground" for processing. In the majority of cases, processing will be justified on the legal basis that:<br /><br />



                    you have provided your consent for us to use your personal information for a specific purpose;<br /><br />


                    our use of your personal information is necessary to perform a contract or take steps to enter into a contract with you (e.g. to provide you with services which you have purchased);<br /><br />


                    the processing is necessary to comply with a relevant legal obligation or regulatory obligation that we have (e.g. fraud prevention); or


                    the processing is necessary to support our legitimate interests as a business (e.g. to improve our services to you), subject to your overriding interests and fundamental rights and provided it is conducted at all times in a way that is proportionate.<br /><br />


                    <h2 className="mt-1 mb-2 text-[18px] leading-[28px] font-bold  ">How Do We Use The Information Collected?</h2>


                    We will use your personal information for the following purposes:<br /><br />



                    to provide you with quality service and security, to operate the Site, to fullfil our obligations to you and to develop, improve and personalize our service. For example, we use personal information to verify your identity. We also use this information to establish and set up your account, verify or re-issue a password, log your activity, enable your communications with other members, provide customer support and contact you from time to time. The information helps us to develop and improve our services, to understand and analyse our performance as well as your preferences and performance and to customize and personalize our service and enhance your experience (including by making Gig suggestions, ranking search results, etc.).<br /><br />


                    to ensure marketplace integrity, prevent fraud and maintain a safe and secure marketplace and services. For example, we use personal information to monitor, track and prevent illegal, fraudulent and inappropriate activities, monitor content integrity, conduct security investigations and risk assessments, verify or authenticate information provided by you, investigate reports and complaints about users, enforce our Terms of Service and comply with applicable laws. We conduct certain behavioural analytics to achieve the above objectives and in limited cases, if we detect activity that we think poses a risk to the Heromay marketplace, other users, our community, or third parties, automated processes may restrict or limit your ability to use Heromay. If you would like to challenge any such decision, please contact us at admin@Heromay.com.<br /><br />


                    to contact you, as requested by you or as otherwise approved by you or permitted according to this Policy.<br /><br />


                    to promote and advertise the Site, our services and the Heromay marketplace. For example, we use the information collected from you for the purpose of sending direct marketing messages (as detailed below), to show you information that may be of interest to you, to organize and facilitate referral programs, contests or other promotional activities or events.<br /><br />


                    to maintain appropriate business records, to comply with lawful requests by public authorities and to comply with applicable laws and regulations.<br /><br />


                    We will ask for your consent before using information for a purpose other than those set out in this Policy.<br /><br />



                    <h2 className="mt-1 mb-2 text-[18px] leading-[28px] font-bold  ">Direct marketing:</h2>



                    We use your personal information to send you direct marketing communications about our products, services or promotions from Heromay that may be of interest to you or our related services. This may be via email, post, SMS, telephone or targeted online advertisements.<br /><br />


                    Our processing of your personal information for marketing purposes is based on our legitimate interest, on your consent, or on other legal basis permitted by applicable laws. You have a right to prevent direct marketing of any form at any time - this can be exercised by following the opt-out link attached to each communication or by sending an email to admin@Heromay.com.<br /><br />


                    We take steps to limit direct marketing to a reasonable and proportionate level, and to send you communications which we believe may be of interest or relevance to you, based on the information we have about you.<br /><br />


                    This Site is also protected by reCAPTCHA. Google Privacy Policy and Terms of Service apply with respect to data collected by reCAPTCHA. The use of Google reCAPTCHA is required in order to prevent fraud and misuse of the Site by use of automated machines. To learn more, please see Google's privacy policy.<br /><br />



                    <h2 className="mt-1 mb-2 text-[18px] leading-[28px] font-bold  ">How Long Do We Keep Personal Information?</h2>


                    We apply a general rule of keeping personal information only for as long as is required to perform the purpose for which it was collected. However, in some circumstances, we will retain your personal information for longer periods of time. We will retain personal information for the following purposes:<br /><br />



                    as long as it is necessary and relevant for our operations, e.g. so that we have an accurate record of your dealings with us in the event of any complaints or challenges;<br /><br />


                    in relation to personal information from closed accounts to comply with applicable laws, prevent fraud, collect any fees owed, resolve disputes, troubleshoot problems, assist with any investigation, enforce our Site terms and take other actions as permitted by law;<br /><br />


                    to comply with statutory or other obligations to which Heromay is subject to; and<br /><br />


                    if and to the extent necessary, we need it to preserve evidence within the statutes of limitation.<br /><br />


                    <h2 className="mt-1 mb-2 text-[18px] leading-[28px] font-bold  ">Children</h2>


                    This Site is offered and available to users who are at least 18 years of age or older and of legal age to form a binding contract. Minors under 18 and at least 13 years of age, are only permitted to use the Site through an account owned by a parent or legal guardian with their appropriate permission. Minors under 13 are not permitted to use the Site or the Heromay services. We do not knowingly collect personal information from children under 13. Parents and guardians should at all times supervise their children's activities. If we learn we have collected or received personal information from a child under 13, we will delete that personal information. If you believe we might have any information from or about a child under 13, please contact us at admin@Heromay.com.<br /><br />



                    <h2 className="mt-1 mb-2 text-[18px] leading-[28px] font-bold  ">Sharing Personal Information with Third Parties</h2>


                    We share your personal information with third parties as detailed below:<br /><br />



                    <b>With other users and Site visitors.</b> Once you register, your username, country, and additional information regarding your activity is made public and is visible to all users of the Site. This information includes photos you upload, your published portfolio, Gig information, ratings and reviews, and additional information you may choose to add to your profile. We also offer you the opportunity to participate and post content publicly in forums, blogs and on other similar features. Please be aware that certain information, such as your user name and profile, together with the content uploaded by you, will be publicly displayed when you use such features. If you were verified as a business seller (trader) on Heromay, certain EU buyers may see some of the information as required by applicable laws (this includes you name, address, email, phone and business registration number, as well as your self-certification to comply with Heromay's policies and EU applicable rules). Your information will also be shared with other users when you message or otherwise communicate with them. When you publish your information and/or share your data with other Heromay users, you do so at your own risk. If you choose to share personal information with other users, including Sellers providing you a service via Heromay, such personal information is shared with other users as separate controllers. While our Terms of Service require Sellers to keep the information shared with them confidential, Heromay cannot ensure that all users will do so and it is your responsibility to ensure that other users will comply with your standards;<br /><br />


                    <b>With users with whom you interact.</b> We share personal information to help facilitate interactions between users. For example, if you are a Buyer we may share certain information about you (such as the fact it is your first purchase on Heromay) with the Seller to ensure the services you receive from the Seller will fit your needs; such sharing of information is limited in scope, and is intended to improve the services provided by the Seller and your user experience;<br /><br />


                    <b>With Service Providers.</b> We share personal information for business purposes with service providers that provide us with services for the purpose of operating the Site, opening and operating your account as well as providing ancillary services and solutions. These include, among others, hosting services, billing and payment processors and vendors, fraud detection and prevention services, CRM systems, forum hosting, community management services, data and cybersecurity services, web analytics and performance tools, translation tools, IT SaaS services, session recording, communication systems, mailing systems, data optimization and marketing services, data enrichment services, legal and financial advisors and technical consultants. Consistent with applicable legal requirements, we take appropriate technical and organizational measures to require third parties to adequately safeguard personal information and only process it in accordance with our instructions;<br /><br />


                    <b>When you submit a report or complaint about a user.</b> We may share personal information about you when you submit reports or complaints about other users to allow them to protect their rights or as required by applicable laws.<br /><br />


                    <b>For legal reasons.</b> We share personal information with law enforcement agencies, public authorities or other parties in order to respond to a subpoena or court order, judicial process or to regulatory authorities, if we believe we are required to do so by law, or that doing so is reasonably necessary to comply with legal processes; when we believe it necessary or appropriate to disclose personal information to law enforcement authorities, such as to investigate actual or suspected fraud or violations of law, breaches of security, or breaches of this Policy; to respond to claims against us; and to protect the rights, property, or personal safety of Heromay, our customers, or the public;<br /><br />


                    <b>For payments and fraud detection.</b> We share personal information with payment processors, fraud detection agencies and similar third parties for the purpose of facilitating payments done via the Site, securing the Site and protecting it against fraud, unauthorized transactions (such as money laundering), claims or other liabilities;<br /><br />


                    <b>With social media partners.</b> Our Site includes certain social media features, including single sign on features. We also share limited personal information with such social media platforms and marketing partners, such as Facebook and Google, to promote our services or the Heromay marketplace. Such features and third party services collect certain information about you and may set a cookie to enable their functionality. Such third parties’ features are governed by their policies;<br /><br />


                    <b>With our corporate affiliates.</b> We share personal information with Heromay’s corporate affiliates for business and operational purposes, including for the purpose of operating our and our affiliates’ business, providing supplemental services to you, preventing fraud and maintaining safe and secure services, promoting our and our affiliates’ services and for other purposes listed herein; and<br /><br />


                    <b>In the context of a corporate transaction.</b> Personal information will also be disclosed if we go through a business transition such as a merger, sale, transfer of all or a portion of Heromay's assets, acquisition, bankruptcy or similar event. In the event that we sell any business or assets, we will disclose your data to the prospective buyer. If we or substantially all of our assets are acquired by a third party, information held by us about our users will be one of the transferred assets.<br /><br />


                    <h2 className="mt-1 mb-2 text-[18px] leading-[28px] font-bold  ">Where We Process Your Personal Information</h2>


                    Some of the personal information you provide to us will be stored or processed on our behalf by third party suppliers and data processors and may be located in other jurisdictions, such as the United States and Israel, whose laws may differ from the jurisdiction in which you live. Whether to third parties or internally, there are also transfers of personal information from the European Economic Area (“EEA”) to countries outside of the EEA not deemed to provide an adequate level of data protection from a European data protection perspective, such as the United States. With regard to data transfers to such recipients outside of the EEA we provide appropriate safeguards, in particular, by way of entering into European Union (EU) standard contractual clauses as amended from time to time, and/or equivalent data transfer safeguards. We will take all steps reasonably necessary to ensure that your personal information is treated securely and in accordance with this Policy.<br /><br />



                    <h2 className="mt-1 mb-2 text-[18px] leading-[28px] font-bold  ">Cookies</h2>


                    We use cookies and similar technologies (such as web beacons, pixels, tags, and scripts) to improve and personalize your experience, provide our services, analyze website performance and for marketing purposes. Where the use of cookies on our Site involves the processing of your personal information, this Policy applies. The types of cookies used by us and our partners in connection with the Site and our service can be classified into the categories “essential cookies”, “analytics cookies” and “marketing cookies”.<br /><br />



                    <h2 className="mt-1 mb-2 text-[18px] leading-[28px] font-bold  ">Do Not Track</h2>


                    Do Not Track (DNT) is a privacy preference that users can set in some web browsers, allowing users to opt out of tracking by websites and online services. We do not honor browser requests not to be tracked online (known as “Do Not Track”), but our Cookie Policy describes how you can opt out of receiving cookies.<br /><br />



                    <h2 className="mt-1 mb-2 text-[18px] leading-[28px] font-bold  ">External Links</h2>


                    Please note that the Site contains links to third party sites and if you navigate to a third party site from the Site, any data you provide to that site and any use of that data by the third party are not under the control of Heromay and are not subject to this Policy. You should consult the privacy policies of each site you visit. This Policy applies solely to personal information collected by our Site. If you upload content, including personal information, to a social network and then tag the Site, your submission will be subject to that social network's terms of use and privacy policy, even where you post on an official Heromay page on the social network. We do not have control over these terms of use and privacy policies and have not reviewed their adequacy. You should therefore review these before submitting any personal information.<br /><br />



                    <h2 className="mt-1 mb-2 text-[18px] leading-[28px] font-bold  ">Security</h2>


                    We implement technical and organizational measures in order to maintain the security of the Site, our services and personal information and in preventing unauthorized access, loss, misuse, alteration, destruction or damage to it through industry standard technologies and internal procedures. Among other things, we regularly maintain a PCI DSS (Payment Card Industry Data Security Standards) certification (with respect to payment by credit cards), as required. In addition, we contractually ensure that any third party processing your personal information equally provides for confidentiality and integrity of your data in a secure way. However, the transmission of data via the internet is not completely secure, and although we will do our best to protect your personal information, we cannot guarantee the security of your data transmitted to the Site. Once we have received your data, we will use strict procedures and security features to try to prevent unauthorized access.<br /><br />



                    Users who have registered to the Site agree to keep their password in strict confidence and not disclose such password to any third party.<br /><br />



                    Further information about our data security practices can be provided on request.<br /><br />


                    <h2 className="mt-1 mb-2 text-[18px] leading-[28px] font-bold  ">Specific Provisions for EU, EEA and UK Users</h2>


                    This section of the Policy applies to you if you are in the EU, the European Economic Area (EEA) or UK.<br /><br />



                    The Controller (within the meaning of the EU and UK General Data Protection Regulation (“GDPR”)) for the processing of personal data as described in this Privacy policy is: Heromay Enterprise, Malaysia.<br /><br />



                    Under applicable EU regulation, you have the following rights in respect of your personal information:<br /><br />



                    to obtain information about how and on what basis your personal information is processed and to obtain a copy;<br /><br />


                    to rectify inaccurate personal information;<br /><br />


                    to erase your personal information in limited circumstances where (a) you believe that it is no longer necessary for us to hold your personal information; (b) we are processing your personal information on the basis of legitimate interests and you object to such processing, and we cannot demonstrate an overriding legitimate ground for the processing; (c) where you have provided your personal information to us with your consent and you wish to withdraw your consent and there is no other ground under which we can process your personal information; and (d) where you believe the personal information we hold about you is being unlawfully processed by us;<br /><br />


                    to restrict processing of your personal information where: (a) the accuracy of the personal information is contested; (b) the processing is unlawful but you object to the erasure of the personal information; (c) we no longer require the personal information for the purposes for which it was collected, but it is required for the establishment, exercise or defence of a legal claim or (d) you have objected to us processing your personal information based on our legitimate interests and we are considering your objection;<br /><br />


                    to object to decisions which are based solely on automated processing or profiling;<br /><br />


                    where you have provided your personal information to us with your consent, to ask us for a copy of this data in a structured, machine-readable format and to ask us to share (port) this data to another data controller; or<br /><br />


                    to obtain a copy of or access to safeguards under which your personal information is transferred outside of the EEA.<br /><br />


                    Under certain circumstances, you may have the right to object, on grounds relating to your particular situation, to the processing of your personal data by us and we may be required to no longer process your personal data. Moreover, if your personal data is processed for direct marketing purposes, you have the right to object at any time to the processing of personal data concerning you for such marketing, which includes profiling to the extent that it is related to such direct marketing. In this case your personal data will no longer be processed for such purposes by us.<br /><br />



                    To exercise your rights please contact us as stated in the “Contact Us” section.<br /><br />



                    In addition to the above, you have the right to lodge a complaint with a supervisory authority for data protection. Please note that the right of access and the right to erasure do not constitute absolute rights and the interests of other individuals may restrict your right of access or erase in accordance with local laws.<br /><br />



                    We will ask you for additional data to confirm your identity and for security purposes, before disclosing data requested by you. We reserve the right to charge a fee where permitted by law. We will decline to process requests that jeopardize the privacy of others, are extremely impractical, or would cause us to take any action that is not permissible under applicable laws. Additionally, as permitted by applicable laws, we will retain where necessary certain personal information for a limited period of time for record-keeping, accounting and fraud prevention purposes.<br /><br />


                    <h2 className="mt-1 mb-2 text-[18px] leading-[28px] font-bold  ">Specific Provisions for US Residents</h2>


                    This section of the Policy applies to you, if you are a us resident, depending on where you live and subject to certain exceptions.<br /><br />



                    During the last twelve (12) months we have collected the following categories of personal information from users:<br /><br />



                    Information that you chose to upload or otherwise provided by you to Heromay, which may include: (i) Identifiers and personal information, such as name, postal addresses, online identifiers, email addresses, passport number or driving license number, social security number; (ii) characteristics of protected classifications, such as gender; facial image; audio, electronic or similar information; (iii) professional or employment-related information; (iv)education information; (v) commercial information; (vi) Audio or other sensory information, for example if you provide audio-based services on Heromay.<br /><br />


                    Information we collect when you use Heromay, including (i) Identifiers and personal information, such as online identifiers, internet protocol (IP) addresses, access device and connection information such as browser type, version, and time zone setting and browser plug-in types and versions; (ii) commercial information, including products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies; (iii) Internet or other electronic network activity information, including, but not limited to log-in and log-out time, the duration of sessions, the content uploaded and downloaded, viewed web-pages or specific content on web-pages, activity measures; (iv) Location information.<br /><br />


                    Information that we collect or receive from third parties, such as service providers, advertisers, and third-party accounts you link with Heromay, including: (i) Identifiers and personal information, such as name, online identifiers, email addresses, internet protocol (IP) addresses, access device and connection information such as browser type, version, and time zone setting and browser plug-in types and versions; (ii) Professional or employment-related information; (iii) Internet or other electronic network activity information, including, but not limited to log-in and log-out time, the duration of sessions, the content uploaded and downloaded, viewed web-pages or specific content on web-pages, activity measures; (iv) Commercial information; and (v) Location information.<br /><br />


                    Inferences drawn from any of the information identified above to create a profile about you.<br /><br />


                    We use the personal information that we collect or receive for the business purposes as described above under the Section titled “How Do We Use the Information Collected?”.<br /><br />



                    We may disclose the above listed categories of personal information to third parties for business purposes as described above under the Section titled “Sharing Personal Information with Third Parties” in the Privacy Policy. In the preceding twelve (12) months, we have disclosed all the categories of personal information detailed above for business purposes.<br /><br />

                    While we do not sell or share your personal information for monetary compensation, we may share your personal information with our marketing partners, to tailor and personalize Heromay’s offers, ads, and content to suit your preferences while you're browsing other sites. This process may constitute selling or sharing of personal information under certain US state privacy laws. You can ask us not to use or disclose your personal information for such targeted advertising. Just visit our Do Not Sell or Share My Personal Information page to exercise your right. Please note that you may still see generalized Heromay ads after opting out of targeted advertising.<br /><br />

                    You are entitled to the following rights subject to certain exceptions, in relation to personal information related to you:<br /><br />



                    You have a right to request access to the personal information we have collected about you over the past 12 months, including: (i) the categories of personal information we collect about you; (ii) the categories of sources from which the personal information is collected; (iii) the business or commercial purpose for collecting your personal information; (iv) the categories of third parties with whom we have shared your personal information; (v) the specific pieces of personal information that we have collected about you.<br /><br />


                    You have a right to request that we delete personal information related to you that we collected from you under certain circumstances and exceptions.
                    <br /><br />

                    You have a right to correct the personal information you provided us with.<br /><br />


                    You have a right to opt out of selling your personal information.<br /><br />


                    You have a right to opt out of targeted advertisement. <br /><br />


                    You also have a right not to be discriminated against for exercising your rights.<br /><br />


                    You also have a right to submit your request via an authorized agent. If you use an authorized agent to submit a request to access or delete your personal information on your behalf, the authorized agent must: (1) be a person or business entity registered with the relevant Secretary of State to conduct business in relevant state; (2) provide proof of such registration; and (3) provide documentation or other proof indicating that they are authorized to act on your behalf. We may also require you to verify your identity directly with us, and directly confirm with us that you provided the authorized agent permission to submit the request.<br /><br />


                    To make such requests, we kindly ask that you open a Customer Relations ticket. If you do not have an active Heromay account, please contact us at admin@Heromay.com.<br /><br />



                    For Heromay Workspace users, please contact us at support@Heromay.com.<br /><br />



                    We will verify your request using the information associated with your account, including email address. Government identification may also be required.<br /><br />


                    <h2 className="mt-1 mb-2 text-[18px] leading-[28px] font-bold  ">Updating Personal Information</h2>


                    We take steps to ensure that the personal information we collect is accurate and up to date, and we provide you with the opportunity to update your information through your account profile settings. In the event that you believe your information is in any way incorrect or inaccurate, please let us know immediately. We will make sure we investigate the matter and correct any inaccuracies as quickly as possible where necessary or give you ways to update it quickly or to delete it - unless we have to keep that information for legitimate business or legal purposes. When updating your personal information, we will ask you to verify your identity before we can act on your request. If for any reason you have a problem with deleting your personal information, please contact Heromay's Customer Support and we will make reasonable efforts to delete any such information pursuant to any applicable privacy laws.<br /><br />



                    You can review and change your personal information by logging into the Site and visiting your account profile page.<br /><br />



                    If you delete your User Generated Content (“UGC”), as defined in the Heromay Terms of Service, from the Site, copies of your UGC may remain viewable in cached and archived pages, or might have been copied or stored by other Site users. Proper access and use of information provided on the Site, including UGC, is governed by our Terms of Service.<br /><br />



                    <h2 className="mt-1 mb-2 text-[18px] leading-[28px] font-bold  ">Contact Us</h2>


                    To exercise any of your rights in connection with your personal information, we kindly ask that you open a Customer Relations ticket. Please include all the relevant details, so your ticket can be handled correctly. We will process any requests in line with any local laws and our policies and procedures. You may also contact us at admin@Heromay.com or by postal mail: Heromay Enterprise, Malaysia (Attn: Legal Department).<br /><br />



                    If you have any questions (or comments) concerning this Policy, please email our team at admin@Heromay.com, and we will make an effort to reply within a reasonable timeframe.<br /><br />



                    For Heromay Workspace users, please contact us at support@Heromay.com.<br /><br />



                    For the purposes of GDPR, you may also contact: Heromay Enterprise, UNIT 0912. BLOCK A2. PARKLANE OUG SERVICE APARTMENT. NO.1 JALAN 1/152. TAMAN OUG PARKLANE 58200 KUALA LUMPUR, Malaysia.<br /><br />
                </p>
            </div>
        </div >
    );
};

export default Privacy;
