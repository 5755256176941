import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isEmpty } from '../../utils/common';
import ImgCustomer from '../../assets/images/customer-hero.png';
import Header from '../../components/Header/Header';
import { NotificationManager } from "react-notifications";
import { Spin } from 'antd';
import { updateUserData } from '../../redux/actions/auth';
import { USER_TYPE } from '../../constants/config';
import { useDispatch } from 'react-redux';

const Mode = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loadingCustomer, setLoadingCustomer] = useState(false);
    const [loadingHero, setLoadingHero] = useState(false);

    const onCustomer = () => {
        setLoadingCustomer(true);
        dispatch(updateUserData({ user_role: USER_TYPE.customer }))
            .then((res) => {
                setLoadingCustomer(false);
            })
            .catch(e => {
                console.log(e)
                setLoadingCustomer(false);
                NotificationManager.error(
                    e?.message ||
                    "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                    "Error",
                    3000
                );
            });
    }

    const onHere = () => {
        setLoadingHero(true);
        dispatch(updateUserData({ user_role: USER_TYPE.hero }))
            .then((res) => {
                setLoadingHero(false);
            })
            .catch(e => {
                console.log(e)
                setLoadingHero(false);
                NotificationManager.error(
                    e?.message ||
                    "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                    "Error",
                    3000
                );
            });
    }

    return (
        <div className='w-full h-full py-5 bg-primary1 flex flex-col justify-center items-center'>
            <Header />
            <h1 className='mt-10 text-[18px] text-white font-bold text-center'>Are you a Hero or Customer?</h1>
            <p className='mt-3 text-center text-[12px] text-[#999] font-medium'>You can change the mode later</p>
            <div className='w-full flex-1 flex flex-col justify-center items-center pt-10 pb-10'>
                <img src={ImgCustomer} className='w-[76%] object-contain' />
            </div>
            <div className='w-full px-5'>
                {loadingHero  ?
                    <div className='mt-9 w-full flex justify-center items-center h-[42px]'>
                        <Spin />
                    </div>
                    :
                    <button className='mt-9 w-full h-[42px] rounded-[4px] bg-primary2 text-white text-[14px] font-semibold' onClick={onHere}>Hero</button>
                }
                {loadingCustomer ?
                    <div className='mt-9 w-full flex justify-center items-center h-[42px]'>
                        <Spin />
                    </div>
                    :
                    <button className='mt-3 w-full h-[42px] rounded-[4px] bg-[#b60589] text-white text-[14px] font-semibold' onClick={onCustomer}>Customer</button>
                }
            </div>
        </div>
    )
}

export default Mode;