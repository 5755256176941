import React, { useRef, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function ProgressTimer({ duration }) {
  const _timer = useRef(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (_timer.current) {
      clearInterval(_timer.current);
    }
    _timer.current = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(_timer.current);
        }
        const diff = parseFloat(100 / (2 * duration));
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(_timer.current);
    };
  }, [duration]);

  console.log('progress ', progress)
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
}