import { Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import './index.css'
import { APP_SET_ENLARGED_IMAGE } from "../../redux/actions/types";

const EnlargedImageModal = () => {
  const dispatch = useDispatch();
  const { enlargedImg } = useSelector(state => state.app);

  const onClose = () => {
    dispatch({ type: APP_SET_ENLARGED_IMAGE, payload: null })
  }

  if (enlargedImg == null) {
    return null;
  }

  return (
    <Modal
      open={enlargedImg != null}
      footer={null}
      onCancel={onClose}
      closeIcon={null}
      closable={false}
      maskClosable={true}
      centered
      width={290}
      className="w-full  rounded-[24px] enlarged-image-modal"
    >
      <div className="w-full">
        <img src={enlargedImg} className={`w-[280px] h-[280px] rounded-full object-cover `}
        />
      </div>
    </Modal>
  );
};

export default EnlargedImageModal;


