import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, updateUserData } from "../../redux/actions/auth";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom"; 
import { FiArrowLeft, FiBell, FiChevronRight, FiList } from "react-icons/fi"; 
import { MdClose } from "react-icons/md";
import { Switch } from "antd";

const JobSettings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sortOptions = [{ id: 'nearby', name: 'Nearby' }, { id: 'new', name: 'New' }];
    const { user } = useSelector(state => state.auth)

    const onChangeOption = (option) => {
        dispatch(updateUserData({ job_sort: option.id }))
            .then((res) => {
                // navigate(-1);
            })
            .catch(e => {
                console.log(e)
                NotificationManager.error(
                    e?.message ||
                    "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                    "Error",
                    3000
                );
            });
    };

    const onChangeNoti = (value) => {
        dispatch(updateUserData({ new_job_noti: value }))
            .then((res) => {
                // navigate(-1);
            })
            .catch(e => {
                console.log(e)
                NotificationManager.error(
                    e?.message ||
                    "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                    "Error",
                    3000
                );
            });
    };

    return (
        <div className='w-full h-full   bg-white-primary1 flex flex-col justify-center items-center'>
            <div className="w-full flex justify-start items-center  py-4  bg-white-primary1 drop-shadow">
                <div className="w-[50px] flex justify-center items-center">
                    <MdClose size={20} className='text-primary1-white' onClick={() => navigate(-1)} />
                </div>
                <div className=" flex-1 text-center text-[16px] text-primary1-white font-bold ml-3">Settings</div>
                <div className="w-[50px] flex justify-center items-center"></div>
            </div>
            <div className='mt-6 flex-1 w-full flex flex-col px-5 justify-start items-center'>
                <div className="w-full flex justify-start items-center">
                    <FiList size={18} className='text-primary1-white' />
                    <p className="ml-3 text-[15px] text-primary1-white font-semibold">Show first</p>
                </div>
                <div className="mt-3 w-full flex justify-start items-center flex-wrap gap-3">
                    {
                        sortOptions.map(option =>
                            <div key={option.id} className={`${user?.job_sort != option.id ? 'bg-[#444]' : 'bg-primary2'} px-6 py-2 rounded-full text-[14px] text-white font-semibold`} onClick={() => onChangeOption(option)}>{option.name}</div>
                        )
                    }
                </div>
                <div className="mt-5 w-full flex justify-start items-center">
                    <FiBell size={18} className='text-primary1-white' />
                    <p className="ml-3 text-[15px] text-primary1-white font-semibold flex-1">New job found notifications</p>
                    <Switch disabled={false} autoFocus={true} className="bg-[#444]"
                        checked={user?.new_job_noti == 1}
                        onChange={onChangeNoti}
                    />
                </div>
            </div>
        </div>
    );
};

export default JobSettings;
