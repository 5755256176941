import React, { useEffect, useState } from "react";
import { Icon } from "react-icons-kit";
import { arrowLeft } from "react-icons-kit/feather/arrowLeft";
import { useNavigate } from "react-router-dom";

const Header = ({ title, right }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }

  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-[50px] flex justify-center items-center">
        <Icon
          icon={arrowLeft}
          className={'text-primary1-white'}
          size={20}
          onClick={goBack}
        />
      </div>
      <div className="flex-1 flex justify-center items-center">{title}</div>
      <div className="w-[50px] flex justify-center items-center">{right}</div>
    </div>
  );
};

export default Header;


