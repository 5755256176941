import React, { useEffect, useState, Suspense } from "react";
import "./index.css";
import AppRoutes from "./routes/appRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getActiveJob, getHeroActiveBid, initCountry, initDarkMode, initLanguage } from "../redux/actions/app";
import { useLocation, useNavigate } from "react-router-dom";
import RouteNames from "../constants/route_names";
import { loadLoginData } from "../redux/actions/auth";
import axios from "axios";
import { KEYS, setStorageKey } from "../utils/storage";
import Splash from "../views/splash/Splash";
import { GOOGLE_MAP_API_KEY, JOB_STATUS, USER_TYPE } from "../constants/config";
import EnlargedImageModal from "../components/EnlargedImageModal";
import UpdateActiveJob from "../components/Hooks/updateActiveJob";
import UpdateNewJobsCnt from "../components/Hooks/updateNewJobsCnt";
import Sidebar from "../components/Sidebar";

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn, user, access_token } = useSelector(state => state.auth);
  const { activeBid, activeJob } = useSelector(state => state.app);
  const [appLoaded, setAppLoaded] = useState(false);

  useEffect(() => {
    getGeoInfo();
    loadSettings();
  }, []);

  useEffect(() => {
    const handler = (e) => {
      console.log('heromay beforeinstallprompt ', e)
      e.preventDefault();
      window.pwaPrompt = e;
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  useEffect(() => {
    if (appLoaded) {
      if (!isLoggedIn) {
        navigate('/login')
      }
      else if (user?.address_id == null) {
        navigate('/app/onboard/location');
      }
      else if (user?.user_role == null) {
        navigate('/app/onboard/mode');
      }
      else if (user?.user_name == null) {
        navigate('/app/onboard/username');
      }
      else {
        if (user?.user_role == USER_TYPE.customer) {
          if (activeJob != null) {
            if (activeJob?.status == JOB_STATUS.NEW) {
              if (activeJob?.bid_cnt > 0) {
                navigate(`/customer/jobs/${activeJob.id}`, { state: { job: activeJob } });
              }
              else {
                navigate(`/customer/wait-hero`);
              }
            }
            else {
              navigate(`/customer/accepted-job/${activeJob.id}`, { state: { job: activeJob } });
            }
          }
          else {
            navigate('/customer');
          }
        }
        else if (user?.user_role == USER_TYPE.hero) {
          if (activeJob != null) {
            navigate(`/hero/accepted-job/${activeJob.id}`, { state: { job: activeJob } });
          }
          else {
            navigate('/hero/jobs');
          }
        }
      }
    }

  }, [appLoaded, isLoggedIn, user?.address_id, user?.user_role, user?.user_name, activeBid, activeJob])

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then(async (response) => {
        let data = response.data;
        setStorageKey(KEYS.COUNTRY_CODE, data.country);
      })
      .catch((error) => {
        setStorageKey(KEYS.COUNTRY_CODE, "US");

        // throw new Error("Error with getting country code based on ip");
      });
  };

  const loadSettings = async () => {
    try {
      await dispatch(initLanguage());
      await dispatch(initCountry());
      await dispatch(initDarkMode());
      await dispatch(loadLoginData());
      await dispatch(getHeroActiveBid());
      await dispatch(getActiveJob());
      setAppLoaded(true);
    } catch (error) {
      console.log('loadSettings ', error)
      setAppLoaded(true);
    }
  };

  if (!appLoaded) {
    return <Splash />;
  }

  return (
    <>
      <AppRoutes />
      <EnlargedImageModal />
      <UpdateActiveJob />
      <Sidebar />
      <UpdateNewJobsCnt />
    </>
  );
};

export default App;
