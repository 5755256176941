import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft, FiChevronRight } from "react-icons/fi";
import ConfigService from "../../services/ConfigService";

const Safety = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState('');

    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        ConfigService.getConfig()
            .then((res) => {
                setData(res.data?.safety || '');
            }).catch(e => {
            });
    }
    return (
        <div className='w-full h-full  bg-white-primary1 flex flex-col justify-center items-center'>
            <div className="w-full flex justify-start items-center  py-4 bg-white-primary1 drop-shadow">
                <div className="w-[50px] flex justify-center items-center">
                    <FiArrowLeft size={20} className='text-primary1-white' onClick={() => navigate(-1)} />
                </div>
                <div className=" flex-1 text-center text-[16px]  text-primary1-white font-bold ml-3">Safety</div>
                <div className="w-[50px] flex justify-center items-center"></div>
            </div>
            <div className='mt-6 flex-1 w-full flex flex-col px-5 justify-start items-center'>
                <p className="w-full text-[14px] leading-[22px] text-black2-gray3">
                    {data}
                </p>
            </div>
        </div>
    );
};

export default Safety;
