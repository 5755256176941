import React, { useEffect, useState } from "react";
import './index.css';

const RadarScanAnim = () => {
  return (
    <div class="panel">
      <div class="scanner"></div>
      <ul class="something">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};

export default RadarScanAnim;


