import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Rate, Spin } from "antd";
import { RiWallet3Line } from "react-icons/ri";
import { AiOutlineSafety } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";
import { MdInfoOutline, MdOutlineMessage } from "react-icons/md";
import { FiChevronRight, FiPlusSquare } from "react-icons/fi";
import { FaFacebook } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { SidebarLink } from "./sidebar-link";
import { useNavigate } from "react-router-dom";
import { USER_TYPE } from "../../constants/config";
import { setShowSidebar } from "../../redux/actions/app";
import Avatar from "../Avatar";
import './index.css';
import SupportModal from "../SupportModal";
import { updateUserData } from "../../redux/actions/auth";
import AreYouSureModal from "../AreYouSureModal";
import { isEmpty } from "../../utils/common";

const Sidebar = ({ selected }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);
  const showSidebar = useSelector((state) => state.app.showSidebar);
  const [loading, setLoading] = useState(false);
  const [showChangeModeModal, setShowChangeModeModal] = useState(false);

  const links = [
    {
      href: "/app/safety",
      icon: <AiOutlineSafety size={22} className='text-primary1-white' />,
      label: "Safety",
      key: "safety",
    },
    {
      href: "/app/settings",
      icon: <IoSettingsOutline size={22} className='text-primary1-white' />,
      label: "Settings",
      key: "settings",
    },
    {
      href: "/app/faqs",
      icon: <MdInfoOutline size={22} className='text-primary1-white' />,
      label: "FAQ",
      key: "faq",
    },
    {
      icon: <FiPlusSquare size={22} className='text-primary1-white' />,
      label: "Add to Home Screen",
      key: "add-home",
      onItemClick: () => { handleInstall() }
    },
  ];

  const handleInstall = () => {
    if (window.pwaPrompt) {
      try {
        // Show the prompt
        window.pwaPrompt.prompt();
        // Wait for the user to respond to the prompt
        window.pwaPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          // Clear the deferredPrompt variable
          window.pwaPrompt = null;
        });
      }
      catch (error) {
        console.log('handleInstall error ', error)
      }
    }
  };

  const onClose = () => {
    dispatch(setShowSidebar(false))
  }

  const handleChangeMode = () => {
    setShowChangeModeModal(false)
    setLoading(true);
    dispatch(updateUserData({ user_role: (user?.user_role == USER_TYPE.hero ? USER_TYPE.customer : USER_TYPE.hero) }))
      .then((res) => {
        setLoading(false);
        dispatch(setShowSidebar(false))
      })
      .catch(e => {
        console.log(e)
        setLoading(false);
        NotificationManager.error(
          e?.message ||
          "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
          "Error",
          3000
        );
      });
  }

  return (
    <Drawer
      placement={'left'}
      closable={false}
      onClose={onClose}
      open={showSidebar}
      rootClassName='sidebar max-w-md m-auto '
      className="bg-white-primary1"
    >
      <div className="w-full flex flex-col py-6 ">
        <div className="px-4 pb-4 flex justify-center items-center border-b border-b-[#eee]"
          onClick={() => {
            onClose();
            navigate('/app/profile')
          }}
        >
          <Avatar image={user?.photo} />
          <div className="flex-1 ml-4 flex flex-col justify-center items-start">
            <p className="mt-1 text-[14px] text-primary1-white font-bold">{isEmpty(user?.name) ? user?.user_name : user?.name}</p>
            <div className="flex justify-center items-center"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClose();
                navigate('/app/rates')
              }}
            >
              <Rate
                disabled
                value={parseFloat(user?.rating).toFixed(1)}
                style={{ fontSize: 13, }}
              />
              <span className="text-[11px] text-primary1-white font-semibold">{parseFloat(user?.rating).toFixed(1)} ({user?.rating_cnt})</span>
            </div>
          </div>
          <FiChevronRight size={24} className='text-primary1-white' />
        </div>
        <div className="w-full py-4">
          {
            user?.user_role == USER_TYPE.hero &&
            <SidebarLink
              icon={<RiWallet3Line size={22} className='text-primary1-white' />}
              label="Wallet"
              href={"/hero/wallet"}
            />
          }
          {links.map((link) => (
            <SidebarLink
              {...link}
            />
          ))}
          <a href="https://wa.me/60105119146?text=Support" target={'_blank'}
          >
            <div className="flex items-center gap-2 px-5 py-3">
              <span><MdOutlineMessage size={22} className='text-primary1-white' /></span>
              <p className="ml-2 text-primary1-white text-[15px] font-semibold">Support</p>
            </div>
          </a>
        </div>
        <div className="w-full flex flex-col mt-6 px-5">
          {
            loading ?
              <div className='w-full h-[40px] flex justify-center items-center'>
                <Spin />
              </div>
              :
              user?.user_role == USER_TYPE.hero ?
                <button className=" bg-primary2 text-white font-semibold text-[15px] rounded-[5px] w-full h-[40px]"
                  onClick={() => setShowChangeModeModal(true)}
                >
                  Customer Mode
                </button>
                :
                <button className=" bg-primary2 text-white font-semibold text-[15px] rounded-[5px] w-full h-[40px]"
                  onClick={() => setShowChangeModeModal(true)}
                >
                  Hero Mode
                </button>
          }
          <div className="w-full flex justify-center items-center gap-5 mt-5">
            <div className="flex justify-center items-center w-[36px] h-[36px] rounded-full">
              <FaFacebook size={36} color="#007bff" />
            </div>
            <div className="flex justify-center items-center w-[36px] h-[36px] rounded-full bg-[#f00]">
              <IoLogoYoutube size={20} color="#fff" />
            </div>
          </div>
        </div>
      </div>
      {
        showChangeModeModal &&
        <AreYouSureModal
          title={'Change Mode'}
          desc={
            user?.user_role == USER_TYPE.hero ?
              'Are you sure to change to customer mode?' :
              'Are you sure to change to hero mode?'
          }
          showModal={showChangeModeModal}
          onCancel={() => setShowChangeModeModal(false)}
          onYes={handleChangeMode}
        />
      }
    </Drawer >
  );
};

export default Sidebar;
