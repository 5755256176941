export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_COUNTRY_CODE = "SET_COUNTRY_CODE";
export const SET_SHOW_TOP_BTN = "SET_SHOW_TOP_BTN";
export const SET_SHOW_SIDEBAR = "SET_SHOW_SIDEBAR";
export const SET_DARK_MODE = "SET_DARK_MODE";

// APP
export const APP_SET_USER_DATA = "APP_SET_USER_DATA";
export const APP_SET_ACCESS_TOKEN = "APP_SET_ACCESS_TOKEN";
export const APP_SET_LOGOUT = "APP_SET_LOGOUT";
export const APP_SET_ENLARGED_IMAGE = "APP_SET_ENLARGED_IMAGE";

// job
export const APP_SET_ACTIVE_BID = "APP_SET_ACTIVE_BID";
export const APP_SET_ACTIVE_JOB = "APP_SET_ACTIVE_JOB";
export const APP_SET_NEW_JOBS_CNT = "APP_SET_NEW_JOBS_CNT";

// track
export const SET_TRACK_DATA = "SET_TRACK_DATA";