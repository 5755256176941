import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { updateUserData } from '../../redux/actions/auth';
import { isEmpty } from '../../utils/common';
import { NotificationManager } from "react-notifications";
import { Spin } from 'antd';

const UserName = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [user_name, setUserName] = useState('')

    const onSubmit = () => {
        setLoading(true);
        dispatch(updateUserData({user_name}))
            .then((res) => {
                setLoading(false);
            })
            .catch(e => {
                console.log(e)
                setLoading(false);
                NotificationManager.error(
                    e?.message ||
                    "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                    "Error",
                    3000
                );
            });
    }

    return (
        <div className='w-full h-full py-5 bg-primary1 flex flex-col justify-center items-center'>
            <Header />
            <div className='mt-10 flex-1 w-full flex flex-col px-5 justify-start items-center'>
                <h1 className='text-[18px] text-white font-bold'>Welcome to HeroMay</h1>
                <p className='mt-3 text-[12px] text-[#999] font-medium'>Please introduce yourself</p>
                <input className='px-2 mt-9 w-full h-[42px] rounded-[4px]' placeholder='Username' value={user_name} onChange={(e) => setUserName(e.target.value)} />
                {loading ?
                    <div className='mt-9 w-full flex justify-center items-center h-[42px]'>
                        <Spin />
                    </div>
                    :
                    <button className='mt-7 w-full h-[42px] rounded-[4px] bg-primary2 text-white text-[14px] font-semibold' onClick={onSubmit}>Next</button>
                }
            </div>
        </div>
    )
}

export default UserName;