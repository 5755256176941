import { Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

const PriceInputModal = ({ showModal, onOk, onClose }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [price, setPrice] = useState('')

  useEffect(() => {
    setOpen(showModal);
  }, [showModal])

  const onSubmit = () => {
    if (price >= 0) {
      onOk(price)
    }
  }

  return (
    <Modal
      open={open}
      onCancel={() => onClose()}
      footer={null}
      closeIcon={null}
      centered
      width={360}
      title={'Offer a new price'}
      className="w-full  rounded-[24px]"
    >
      <div className=" ">
        <Input
          placeholder={'Your price'}
          type='number'
          className='mt-4 p-3 w-full h-[42px] rounded bg-[#f5f5f5]'
          prefix={<span className='font-bold text-[#333]'>RM</span>}
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <div className="mt-5 w-full flex justify-center items-center gap-6">
          <Button className="h-[42px] px-6 rounded-full bg-[#333] text-white" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button className="h-[42px] px-6 rounded-full bg-primary2 text-white" loading={loading} onClick={onSubmit}>
            Offer this price
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PriceInputModal;


