import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Switch = ({ value, onChangeValue }) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center rounded-full bg-[#f5f5f5]">
      <div onClick={() => onChangeValue('offline')} className={`cursor-pointer flex-1 px-6 py-2 rounded-full flex justify-center items-center text-[14px] font-semibold ${value == 'offline' ? 'text-white bg-[#f00]': 'text-[#666]'}`}>
        Offline
      </div>
      <div onClick={() => onChangeValue('online')} className={`cursor-pointer flex-1 px-6 py-2 rounded-full flex justify-center items-center text-[14px] font-semibold ${value == 'online' ? 'text-white bg-primary2': 'text-[#666]'}`}>
        Online
      </div>
    </div>
  );
};

export default Switch;


