import { useLocation, useNavigate, useParams } from "react-router-dom";
import RouteNames from "../constants/route_names";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export function AuthMiddleware({ children }) {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      // navigate(`/login`);
    }
  }, [isLoggedIn]);

  return children;
}

export default AuthMiddleware;
