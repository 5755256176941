import apiFactory from "../utils/apiFactory";
import {
  API_Auth
} from "../constants/apiConfig";

export default class AuthService {
  static sendVerifyCode = async (payload) => {
    let api = {
      content_type: "application/json",
      path: `${API_Auth.path}/send-verify`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };
  static verifyCode = async (payload) => {
    let api = {
      content_type: "application/json",
      path: `${API_Auth.path}/verify-code`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };
}
