import React, { useEffect, useState } from 'react'; 
import Autocomplete from 'react-google-autocomplete'; 
 import './index.css'; 
import { getLanguage } from "../../utils/storage";
import { GOOGLE_MAP_API_KEY } from "../../constants/config";
import { IoSearch } from "react-icons/io5"; 

const LocationInput = ({ defaultInput, placeholder, style, onChangePlace }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    // console.log('useEffect ', defaultInput);
    setText(defaultInput);
  }, [defaultInput]);
  // console.log('LocationInput ', defaultInput);
  return (
    <div style={style} className="location-input flex justify-start items-center mt-4 p-3 w-full h-[42px] rounded bg-[#f5f5f5]">
      <div className='w-[29px]'>
        <IoSearch size={20} color={'#333'} />
      </div>
      <Autocomplete
        apiKey={GOOGLE_MAP_API_KEY}
        language={getLanguage()}
        placeholder={placeholder}
        className="flex-1 bg-[#f5f5f5] outline-none text-[13px]"
        value={text}
        options={{
          types: ['geocode'],
          componentRestrictions: { country: ['us', 'cn', 'my'] }
        }}
        onChange={(e) => {
          // console.log('on change ', e.target.value)
          setText(e.target.value);
        }}
        onPlaceSelected={(d) => {
          // console.log('on change place ', d);
          onChangePlace(d);
        }}
      />
    </div>
  );
};
 
function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.placeholder === nextProps.placeholder &&
    prevProps.defaultInput === nextProps.defaultInput &&
    prevProps.onChangePlace === nextProps.onChangePlace
  );
}

export default React.memo(LocationInput, arePropsEqual);
