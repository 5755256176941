import { APP_SET_LOGOUT, APP_SET_ACCESS_TOKEN, APP_SET_USER_DATA } from '../actions/types';

const initialState = {
    isLoggedIn: false,
    user: null,
    access_token: null
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case APP_SET_LOGOUT:
            return { ...state, isLoggedIn: false, user: null, access_token: null };
        case APP_SET_ACCESS_TOKEN:
            return { ...state, isLoggedIn: true, access_token: action.payload };
        case APP_SET_USER_DATA:
            return { ...state, user: action.payload  };
        default:
            return state;
    }
};

export default auth;
