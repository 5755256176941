import React, { useEffect, useState } from "react";
import { MdPermPhoneMsg } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { BiMessageDetail } from "react-icons/bi";
import './index.css';
import { FloatButton } from 'antd';

const WhatsappBtn = ({ user }) => {
  const [isRouteBtnActive, setIsRouteBtnActive] = useState(false);

  return (
    <FloatButton.Group
      trigger="click"
      style={{
        insetInlineEnd: 24,
        position: 'absolute',
        right: 0,
        bottom: 40
      }}
      className={`phone-msg-btn ${isRouteBtnActive ? 'active' : 'inactive'}`}
      open={isRouteBtnActive}
      icon={<MdPermPhoneMsg className='text-white' />}
      onOpenChange={setIsRouteBtnActive}
      onClick={() => {
        setIsRouteBtnActive(pre => !pre)
      }}
    >
      <FloatButton className={`phone-btn `} icon={<FiPhone className='text-white' />}
        href={`https://wa.me/call/${user?.phone}`} target={'_blank'}
      />
      <FloatButton className={`msg-btn `} icon={<BiMessageDetail className='text-white' />}
        href={`https://wa.me/${user?.phone}`} target={'_blank'}
      />
    </FloatButton.Group>
  );
};

export default WhatsappBtn;


