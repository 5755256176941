import { Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClothColors } from "../../constants/config";
import { getActiveJob } from "../../redux/actions/app";
import JobService from "../../services/JobService";
import ClothItem from "../ClothItem";
import ColorPickItem from "../ColorPickItem";
import './index.css'
import { NotificationManager } from "react-notifications";

const ClothModal = ({ showModal, isCustomer = false, job }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [topWear, setTopWear] = useState('LongShirt');
  const [topColor, setTopColor] = useState('White');
  const [bottomWear, setBottomWear] = useState('LongPant');
  const [bottomColor, setBottomColor] = useState('White');

  useEffect(() => {
    setOpen(showModal);
  }, [showModal])

  const onSubmit = async () => {
    const payload = { job_id: job.id, };
    if (isCustomer) {
      payload.customer_wear_top = topWear + topColor;
      payload.customer_wear_bottom = bottomWear + bottomColor;
    }
    else {
      payload.hero_wear_top = topWear + topColor;
      payload.hero_wear_bottom = bottomWear + bottomColor;
    }

    try {
      setLoading(true);
      if (isCustomer) {
        await JobService.updateCustomerWear(payload);
      }
      else {
        await JobService.updateHeroWear(payload);
      }
      await dispatch(getActiveJob());
      setLoading(false);
    } catch (error) {
      console.log(error)
      setLoading(false);
      NotificationManager.error(
        error?.message ||
        "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
        "Error",
        3000
      );
    }
  }

  return (
    <Modal
      open={open}
      footer={null}
      closeIcon={null}
      closable={false}
      maskClosable={false}
      centered
      width={360}
      className="w-full  rounded-[24px] cloth-modal"
    >
      <div className="w-full">
        <p className="text-[16px] leading-[20px] text-white text-center">Tell what are you wearing now,<br />so he can find you eaily</p>
        <div className="mt-3 w-full flex flex-col justify-center items-center">
          <div className="flex w-full justify-around items-center">
            {
              ['Shirt', 'LongShirt', 'Singlet'].map((cloth, index) =>
                <ClothItem key={index}
                  name={cloth}
                  type={'single'}
                  color={cloth == topWear ? topColor : 'White'}
                  isSelected={cloth == topWear}
                  onClick={() => setTopWear(cloth)}
                />
              )
            }
          </div>
          <div className="mt-3 px-5 grid grid-cols-6 gap-[8px]">
            {
              Object.keys(ClothColors).map(color =>
                <ColorPickItem key={color} color={color} isSelected={topColor == color}
                  onClick={() => setTopColor(color)}
                />
              )
            }
          </div>
        </div>
        <div className="mt-3 w-full h-[2px] bg-[#666]" />
        <div className="mt-3 w-full flex flex-col justify-center items-center">
          <div className="flex w-full justify-around items-center">
            {
              ['Pant', 'LongPant', 'Skirt', 'LongSkirt'].map((cloth, index) =>
                <ClothItem key={index}
                  name={cloth}
                  color={cloth == bottomWear ? bottomColor : 'White'}
                  isSelected={cloth == bottomWear}
                  onClick={() => setBottomWear(cloth)}
                />
              )
            }
          </div>
          <div className="mt-3 px-5 grid grid-cols-6 gap-[8px]">
            {
              Object.keys(ClothColors).map(color =>
                <ColorPickItem key={color} color={color} isSelected={bottomColor == color}
                  onClick={() => setBottomColor(color)}
                />
              )
            }
          </div>
        </div>
        <div className="mt-5 w-full flex flex-col justify-center items-center">
          {loading ?
            <Spin />
            :
            <button className="w-[160px] h-[38px] flex justify-center items-center rounded-full bg-primary2 text-[14x] text-white"
              onClick={onSubmit}
            >Save</button>
          }
        </div>
      </div>
    </Modal>
  );
};

export default ClothModal;


