import React, { useEffect, useState } from "react";
import { Drawer, Spin } from 'antd';
import { USER_TYPE } from "../../constants/config";
import './index.css'
import { useSelector } from "react-redux";

const CancelJobModal = ({ showModal, onClose }) => {
    const { user } = useSelector(state => state.auth);
    const [open, setOpen] = useState(false);
    const heroReasons = ['Customer no-show', 'Too much works to do', 'Wrong Address', 'Customer bad attitude', 'Customer asked to cancel', "I can't do this job", "Other"];
    const customerReasons = ['Hero no-show', 'Waited too long for Hero', 'Wrong Address', 'Hero bad attitude', 'Hero asked to cancel', "I don't need Hero anymore", 'Other'];

    useEffect(() => {
        setOpen(showModal)
    }, [showModal])

    return (
        <Drawer
            placement={'bottom'}
            closable={false}
            onClose={() => onClose()}
            open={open}
            rootClassName="w-full max-w-md mx-auto"
            className="cancel-job-modal"
            height={'78vh'}
        >
            <div className="bg-white ">
                <div className="w-full flex justify-center items-center py-4 bg-[#bbb]">
                    <p className="flex-1 text-center text-white text-[20px] font-bold">
                        Why Are you Cancelling This Job?
                    </p>
                </div>
                <div className="w-full mt-4">
                    {
                        (user?.user_role == USER_TYPE.customer ? customerReasons : heroReasons)
                            .map(item =>
                                <div key={item} className="w-full p-4 border-b border-b-[#eee] text-[15px] text-primary2 font-semibold"
                                    onClick={() => onClose(item)}
                                >
                                    {item}
                                </div>
                            )
                    }
                </div>
                <div className="w-full flex justify-center items-center p-5">
                    <div className="text-[15px] text-primary2 font-semibold" onClick={() => onClose()}>Close</div>
                </div>
            </div>
        </Drawer>
    );
};

export default CancelJobModal;


