import React, { useEffect, useState, Suspense } from "react";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { Outlet } from "react-router-dom";

const GuestLayout = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <div className="w-full h-full max-w-md phone-frame flex flex-col">
        <Outlet />
        <NotificationContainer />
      </div>
    </div>
  );
};

export default GuestLayout;
