import apiFactory from "../utils/apiFactory";
import {
  API_User
} from "../constants/apiConfig";

export default class UserService {
  static getProfile = async () => {
    let api = {
      ...API_User,
      path: `${API_User.path}/profile`,
      method: "GET",
    };
    return apiFactory(api);
  };
  static getReviews = async () => {
    let api = {
      ...API_User,
      path: `${API_User.path}/reviews`,
      method: "GET",
    };
    return apiFactory(api);
  };
  static updateLocation = async (payload) => {
    let api = {
      ...API_User,
      path: `${API_User.path}/update-location`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };
}
