import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SupportModal = ({ showModal, onClose }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(showModal);
  }, [showModal])

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      closeIcon={null}
      centered
      width={360}
      className="w-full  rounded-[24px]"
    >
      <div className="p-6">
        <a href="https://wa.me/60105119146?text=Report%20Bug" target={'_blank'}>
          <div className="w-full h-[46px] text-[24px] text-white bg-primary2 rounded-full text-center">Report Bug</div>
        </a>
        <div className="h-[16px]"/>
        <a href="https://wa.me/60105119146?text=Suggestion" target={'_blank'} >
          <div className="w-full h-[46px] text-[24px] text-white bg-primary2 rounded-full text-center">Suggestion</div>
        </a>
        <div className="h-[16px]"/>
        <a href="https://wa.me/60105119146?text=Question" target={'_blank'} >
          <div className="w-full h-[46px] text-[24px] text-white bg-primary2 rounded-full text-center">Other</div>
        </a>
      </div>
    </Modal>
  );
};

export default SupportModal;


