import { GOOGLE_MAP_API_KEY } from "../constants/config";

const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export async function googleTextToSpeech(text) {
  try {
    const apiKey = GOOGLE_MAP_API_KEY;
    if (!apiKey || !text) return;

    const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;

    const data = {
      input: {
        text: text,
      },
      voice: {
        languageCode: "en-US",
        name: "en-US-Neural2-H",
        ssmlGender: "FEMALE",
      },
      audioConfig: {
        audioEncoding: "MP3",
      },
    };

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
    });

    const responseJson = await response.json();
    const blob = b64toBlob(responseJson.audioContent, "audio/mpeg");
    const audiourl = URL.createObjectURL(blob);

    const audio = new Audio(audiourl);
    audio.play();
    // Optional: Revoke the object URL after the audio is loaded to free up memory
    audio.onended = () => {
      URL.revokeObjectURL(url);
    };

  } catch (error) {
    console.log(error);
  }
}
