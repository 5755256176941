import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import JobItem from '../../components/JobItem';
import { useEffect } from 'react';
import JobService from '../../services/JobService';
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from 'react-redux';
import JobSpamReportModal from '../../components/JobSpamReportModal';
import AreYouSureModal from '../../components/AreYouSureModal';
import WaitingCustomerModal from '../../components/WaitingCustomerModal';
import { getHeroActiveBid } from '../../redux/actions/app';
import RadarScanAnim from '../../components/RadarScanAnim';
import RateModal from '../../components/RateModal';
import { getCurrentLocation } from '../../utils/location';
import UserService from '../../services/UserService';

const Jobs = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const _timer = useRef(null);
    const { user } = useSelector(state => state.auth)
    const { activeBid, newJobsCnt, newJobIds } = useSelector(state => state.app)
    const [loading, setLoading] = useState(false);
    const [showSpamModal, setShowSpamModal] = useState(false);
    const [showHideModal, setShowHideModal] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);

    const [showRateModal, setShowRateModal] = useState(false);
    const [completedJob, setCompletedJob] = useState(null);

    useEffect(() => {
        if (_timer.current != null) {
            clearInterval(_timer.current);
        }
        _timer.current = setInterval(function () {
            refreshData();
            dispatch(getHeroActiveBid());
        }, 5000);
        refreshData();
        loadUnratedJob();
        updateCurrentLocation();
        return () => {
            if (_timer.current != null) {
                clearInterval(_timer.current);
            }
        }
    }, [])

    const refreshData = () => {
        setLoading(true);
        JobService.getAll()
            .then((res) => {
                setJobs(res.jobs || []);
                // console.log('jobs ', res)
                setLoading(false);
            }).catch(e => {
                console.log(e)
                setLoading(false);
                NotificationManager.error(
                    e?.message ||
                    "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                    "Error",
                    3000
                );
            });
    }

    const loadUnratedJob = () => {
        JobService.getHeroUnRatedJob()
            .then((res) => {
                if (res.job != null) {
                    setCompletedJob(res.job);
                    setShowRateModal(true);
                }
            }).catch(e => {
            });
    }

    const updateCurrentLocation = async () => {
        try {
            const position = await getCurrentLocation();

            UserService.updateLocation({
                user_id: user.id,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              })
                .then((res) => {
                  // console.log(res);
                })
                .catch((e) => {
                  console.log(e);
                });
        } catch (error) {
            
        }
    }

    const handleHideJob = () => {
        JobService.hideJob({ job_id: selectedJob?.id })
            .then((res) => {
                // console.log('jobs ', res)
                refreshData()
                setShowHideModal(false)
            }).catch(e => {
                console.log(e)
                NotificationManager.error(
                    e?.message ||
                    "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                    "Error",
                    3000
                );
            });
    }

    const goDetails = (job) => {
        navigate(`/hero/jobs/${job.id}`, { state: { job: job } })
    }

    const _jobLoadedCalled = useRef(false);
    const updateJobLoaded = (e) => {
        if (newJobsCnt > 0) {
            if (_jobLoadedCalled.current == false) {
                console.log(e, ' call updateJobLoaded')
                _jobLoadedCalled.current = true;
                JobService.updateJobLoaded({
                    job_ids: newJobIds
                })
                    .then(() => { 
                        setTimeout(() => {
                            _jobLoadedCalled.current = false;
                        }, 5000)
                    })
                    .catch(() => { 
                        setTimeout(() => {
                            _jobLoadedCalled.current = false;
                        }, 5000)
                    })
            }
            else {
                // console.log('ignore call')
            }
        }
    }

    return (
        <div className="w-full flex-1 overflow-y-auto flex flex-col bg-white-primary1 "
            onScroll={()=> updateJobLoaded('onScroll')}
        >
            <div className='w-full bg-white-primary1 flex flex-col justify-center items-center relative'
                onMouseDown={() => updateJobLoaded('onMouseDown')}
                onTouchStart={() => updateJobLoaded('onTouchStart')}
            >
                {
                    jobs.length == 0 ?
                        <div className='w-full overflow-hidden flex flex-col justify-center items-center bg-white-primary1' style={{ height: 'calc(100vh - 134px)' }}>
                            <RadarScanAnim />
                            <div className=' absolute top-[80px] w-full text-center text-[16px] text-primary1-white font-semibold'>
                                Searching for request...
                            </div>
                        </div>
                        :
                        jobs.map(job =>
                            <JobItem key={job.id} data={job}
                                onReportSpam={() => {
                                    setSelectedJob(job);
                                    setShowSpamModal(true);
                                }}
                                onHide={() => {
                                    setSelectedJob(job);
                                    setShowHideModal(true);
                                }}
                                onClick={() => {
                                    goDetails(job)
                                }}
                            />
                        )}
                {
                    showSpamModal &&
                    <JobSpamReportModal
                        job={selectedJob}
                        showModal={showSpamModal}
                        onClose={(flag) => {
                            if (flag) {
                                refreshData()
                            }
                            setShowSpamModal(false)
                        }}
                    />
                }
                {
                    showHideModal &&
                    <AreYouSureModal
                        title={'Hide Job'}
                        desc={'Are you sure to hide this job?'}
                        showModal={showHideModal}
                        onCancel={() => setShowHideModal(false)}
                        onYes={handleHideJob}
                    />
                }
                {
                    activeBid != null &&
                    <WaitingCustomerModal
                        bid={activeBid}
                        showModal={activeBid != null}
                    />
                }
                {
                    showRateModal &&
                    <RateModal
                        title={'Rate your Customer'}
                        showModal={showRateModal}
                        job={completedJob}
                        onClose={() => setShowRateModal(false)}
                    />
                }
            </div>
        </div>
    )
}

export default Jobs;