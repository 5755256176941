import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_TYPE } from "../../constants/config";
import { getNewJobsCnt } from "../../redux/actions/app";
import BeepSound from '../../assets/audios/alarm.mp3';

export default function UpdateNewJobsCnt() {
    const dispatch = useDispatch();
    const _timer = useRef(null);
    const { user } = useSelector(state => state.auth)
    const { newJobsCnt } = useSelector(state => state.app)
    const [audio] = useState(new Audio(BeepSound));

    useEffect(() => {
        if (_timer.current != null) {
            clearInterval(_timer.current);
        }
        _timer.current = setInterval(function () {
            if (user?.user_role == USER_TYPE.hero) {
                dispatch(getNewJobsCnt())
            }
        }, 5000);

        if (user?.user_role == USER_TYPE.hero) {
            dispatch(getNewJobsCnt())
        }

        return () => {
            if (_timer.current != null) {
                clearInterval(_timer.current);
            }
        }
    }, [user])

    useEffect(() => {
        if (user?.user_role == USER_TYPE.hero && newJobsCnt > 0 && user?.new_job_noti == 1) {
            playBeep();
        }

        return () => {
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
            }
        }
    }, [user?.user_role, user?.new_job_noti, newJobsCnt])

    const playBeep = () => {
        try {
            if (audio) {
                audio.play().catch((error) => {
                    console.error("Playback failed: ", error);
                });
            }
        } catch (error) {
            console.log('play beep error ', error)
        }
    };

    return null;
}