import { combineReducers } from "redux";
import app from "./app";
import auth from "./auth";
import track from "./track";

const reducers = combineReducers({
  app,
  auth,
  track
});

export default reducers;
