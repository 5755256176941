import React, { useEffect, useState } from "react";
import { Drawer, Spin } from 'antd';
import { IoClose } from "react-icons/io5";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { GOOGLE_MAP_API_KEY } from "../../constants/config";
import './index.css'
import { extractCityInfo } from "../../utils/common";

const ChooseLocation = ({ showModal, onClose }) => {
    const [open, setOpen] = useState(false);
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: GOOGLE_MAP_API_KEY,
    });

    useEffect(() => {
        setOpen(showModal)
    }, [showModal])

    const onPlaceSelected = (place_id) => {
        placesService?.getDetails(
            {
                placeId: place_id,
            },
            (placeDetails) => {
                let data = extractCityInfo(placeDetails.address_components);

                onClose({
                    latitude: placeDetails.geometry.location.lat(),
                    longitude: placeDetails.geometry.location.lng(),
                    address_line1: data.address_line1,
                    address_line2: data.address_line2,
                    country: data.country,
                    city: data.city
                });
            }
        );
    }

    return (
        <Drawer
            placement={'bottom'}
            closable={false}
            onClose={() => onClose()}
            open={open}
            rootClassName="w-full max-w-md mx-auto"
            className="choose-location-modal"
            height={'84vh'}
        >
            <div className="bg-primary1 ">
                <div className="w-full flex justify-center items-center">
                    <div className="w-[32px]" />
                    <p className="flex-1 text-center text-white text-[20px] font-bold">Choose your location</p>
                    <div className="w-[32px] h-[32px] flex justify-center items-center bg-[#777] rounded-full" onClick={() => onClose()}>
                        <IoClose size={24} color="#fff" />
                    </div>
                </div>
                <div className="w-full mt-4">
                    <input
                        placeholder="Search"
                        className="w-full h-[42px] mb-6 outline-none px-2 bg-[#888] rounded-[5px] border-2 border-[#eee]"
                        onChange={(evt) => {
                            getPlacePredictions({ input: evt.target.value });
                        }}
                    />
                    {
                        isPlacePredictionsLoading ?
                            <div className='w-full flex justify-center items-center h-[42px]'>
                                <Spin />
                            </div>
                            :
                            placePredictions.map((item, index) =>
                                <div key={index} className="w-full py-2 border-b border-b-[#555]" onClick={() => onPlaceSelected(item.place_id)}>
                                    <p className="text-[16px] text-white font-bold">{item?.structured_formatting?.main_text}</p>
                                    <p className="text-[14px] text-[#bbb] font-semibold">{item?.structured_formatting?.secondary_text}</p>
                                </div>
                            )
                    }
                </div>
            </div>
        </Drawer>
    );
};

export default ChooseLocation;


