import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import {
  DarkMapStyles,
  GOOGLE_MAP_API_KEY,
  LightMapStyles,
} from "../../constants/config";
import LocationMarker from "../../components/Markers/LocationMarker";
import PersonMarker from "../../components/Markers/PersonMarker";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../../services/UserService";
import { FloatButton } from "antd";
import { FaCarSide, FaWalking, FaRoute } from "react-icons/fa";
import "./index.css";
import {
  fetchDirections,
  getRouteData,
  getNewPaths,
  calculateBearing,
  getCurStepInfo,
  getDirection,
  getCurrentLocation,
} from "../../utils/location";
import MapInstruction from "./MapInstruction";
import Svg_male from "../../assets/svgs/markers/male.svg";
import Svg_female from "../../assets/svgs/markers/female.svg";
import { setTrackData } from "../../redux/actions/track";
import Estimation from "./Estimation";

function getStrokeWeight(zoom) {
  if (zoom >= 19) {
    return 31;
  } else if (zoom >= 18) {
    return 27;
  } else if (zoom >= 17) {
    return 22;
  } else if (zoom >= 16) {
    return 18;
  } else if (zoom >= 15) {
    return 16;
  } else if (zoom >= 14) {
    return 14;
  } else if (zoom >= 13) {
    return 12;
  } else if (zoom >= 12) {
    return 10;
  }
  return 7;
}

const HeroMap = () => {
  const { user } = useSelector((state) => state.auth);
  const { activeJob } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const _MAP = useRef(null);
  const _MAPS = useRef(null);
  const directionsService = useRef(null);
  const mapView = useRef(null);
  const _userMarker = useRef(null);
  const _routePathLine = useRef(null);
  const _routePathOutLine = useRef(null);
  const _routeIndicator = useRef(null);
  const _intervalTimer = useRef(null);
  const _locationTimer = useRef(null);
  const _lineStrokeWidth = useRef(7);
  const _pathsData = useRef([]);
  const _stepsData = useRef([]);
  const userOldLoc = useRef({
    lat: user?.cur_latitude,
    lng: user?.cur_longitude,
  });
  const _viewMode = useRef('overview');
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [viewMode, setViewMode] = useState('overview');
  const [isRouteBtnActive, setIsRouteBtnActive] = useState(false);

  useEffect(() => {
    if (isMapLoaded) {
      if (viewMode === "car") {
        loadDrivingRoutes();
      } else if (viewMode === "walk") {
        loadWalkingRoutes();
      } else {
        dispatch(setTrackData(null));
        initRoutes();
      }
    }
    _viewMode.current = viewMode;
  }, [isMapLoaded, viewMode]);

  const _tmpUserLocs = useRef([]);
  const _loc = useRef(1);
  const getLocation = (position) => {
    // if (_loc.current >= _tmpUserLocs.current.length) {
    //   console.log("THIS IS LAST STEP !!!!!!!!!!");
    //   return;
    // }
    // var crd = _tmpUserLocs.current[_loc.current]; // TEST
    // _loc.current = _loc.current + 1; // TEST

    var crd = { lat: position.coords.latitude, lng: position.coords.longitude }; // LIVE
    console.log(`Position : ${crd.lat}  : ${crd.lng}`);

    drawLines(crd);
  };

  useEffect(() => {
    if (_locationTimer.current) {
      clearInterval(_locationTimer.current);
    }

    if (navigator.geolocation) {
      _locationTimer.current = setInterval(() => {
        getCurrentLocation()
          .then(getLocation)
          .catch(() => {});
      }, 1000);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
    return () => {
      if (_locationTimer.current) {
        clearInterval(_locationTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (_intervalTimer.current) {
      clearInterval(_intervalTimer.current);
    }

    _intervalTimer.current = setInterval(() => {
      UserService.updateLocation({
        user_id: user.id,
        latitude: userOldLoc.current.lat,
        longitude: userOldLoc.current.lng,
      })
        .then((res) => {
          // console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
    }, 10000);
    return () => {
      if (_intervalTimer.current) {
        clearInterval(_intervalTimer.current);
      }
    };
  }, []);

  const handleApiLoaded = (map, maps) => {
    _MAPS.current = maps;
    _MAP.current = map;
    setIsMapLoaded(true);
    directionsService.current = new maps.DirectionsService();

    maps.event.addListener(map, "zoom_changed", function () {
      const newZoom = map.getZoom();
      _lineStrokeWidth.current = getStrokeWeight(newZoom);
      if (_routePathLine.current) {
        _routePathLine.current.setOptions({
          strokeWeight: _lineStrokeWidth.current,
        });
      }
      if (_routePathOutLine.current) {
        _routePathOutLine.current.setOptions({
          strokeWeight: _lineStrokeWidth.current + 1,
        });
      }
      if (_routeIndicator.current) {
        _routeIndicator.current.setOptions({
          strokeWeight: _lineStrokeWidth.current - 2,
        });
      }
    });
  };

  const initRoutes = () => {
    fetchDirections(
      directionsService.current,
      {
        lat: parseFloat(userOldLoc.current.lat),
        lng: parseFloat(userOldLoc.current.lng),
      },
      {
        lat: parseFloat(activeJob?.latitude),
        lng: parseFloat(activeJob?.longitude),
      },
      "DRIVING"
    )
      .then((result) => {
        const data = getRouteData(result);
        _pathsData.current = data?.full_paths || [];
        _tmpUserLocs.current = _pathsData.current;
        _stepsData.current = data?.stepsData || [];
        _MAP.current.setTilt(45);
        _MAP.current.setZoom(14);
        _lineStrokeWidth.current = getStrokeWeight(14);
      })
      .catch((err) => {
        console.log("fetch directions err ", err);
      });
  };

  const loadDrivingRoutes = () => {
    fetchDirections(
      directionsService.current,
      {
        lat: parseFloat(userOldLoc.current.lat),
        lng: parseFloat(userOldLoc.current.lng),
      },
      {
        lat: parseFloat(activeJob?.latitude),
        lng: parseFloat(activeJob?.longitude),
      },
      "DRIVING"
    )
      .then((result) => {
        const data = getRouteData(result);
        _pathsData.current = data?.full_paths || [];
        _tmpUserLocs.current = _pathsData.current;
        _stepsData.current = data?.stepsData || [];

        console.log("_stepsData.current ", _stepsData.current);
        _MAP.current.setTilt(60);
        _MAP.current.setZoom(18);
        _lineStrokeWidth.current = getStrokeWeight(18);
      })
      .catch((err) => {
        console.log("fetch directions err ", err);
      });
  };

  const loadWalkingRoutes = () => {
    fetchDirections(
      directionsService.current,
      {
        lat: parseFloat(userOldLoc.current.lat),
        lng: parseFloat(userOldLoc.current.lng),
      },
      {
        lat: parseFloat(activeJob?.latitude),
        lng: parseFloat(activeJob?.longitude),
      },
      "WALKING"
    )
      .then((result) => {
        const data = getRouteData(result);
        _pathsData.current = data?.full_paths || [];
        _tmpUserLocs.current = _pathsData.current;
        _stepsData.current = data?.stepsData || [];
        _MAP.current.setTilt(60);
        _MAP.current.setZoom(18);
        _lineStrokeWidth.current = getStrokeWeight(18);
      })
      .catch((err) => {
        console.log("fetch directions err ", err);
      });
  };

  const drawLines = (userLoc) => {
    if (
      userLoc?.lat == null ||
      userLoc?.lng == null ||
      (_routePathLine.current &&
        userOldLoc.current?.lat == userLoc.lat &&
        userOldLoc.current?.lng == userLoc.lng)
    ) {
      return;
    }

    userOldLoc.current = userLoc;
    _pathsData.current = getNewPaths(_pathsData.current, userLoc);

    if (_pathsData.current == null || _pathsData.current.length < 2) {
      return;
    }

    if (_routePathLine.current) {
      _routePathLine.current.setMap(null); // clear previous polylines
    }
    if (_routePathOutLine.current) {
      _routePathOutLine.current.setMap(null);
    }
    if (_routeIndicator.current) {
      _routeIndicator.current.setMap(null);
    }
    if (_userMarker.current) {
      _userMarker.current.setMap(null); // clear previous polylines
    }

    if (_viewMode.current == 'overview') {
      _routePathLine.current = new _MAPS.current.Polyline({
        path: _pathsData.current,
        strokeColor: "#007bff",
        strokeOpacity: 1,
        strokeWeight: _lineStrokeWidth.current,
        zIndex: 1,
      });

      _userMarker.current = new _MAPS.current.Marker({
        position: _pathsData.current[0],
        icon: Svg_male,
      });
      _userMarker.current.setMap(_MAP.current);

      _routePathLine.current.setMap(_MAP.current);
      // _MAP.current.setCenter(_pathsData.current[0]);

      const bounds = new _MAPS.current.LatLngBounds();
      bounds.extend(_pathsData.current[0]);
      bounds.extend(_pathsData.current[_pathsData.current.length - 1]);
    } else {
      const circleicon = {
        path: _MAPS.current.SymbolPath.CIRCLE,
        fillColor: "#007bff",
        fillOpacity: 1,
        scale: 36,
        strokeColor: "#ddd",
        strokeWeight: 5,
      };
      const arrowicon = {
        path: _MAPS.current.SymbolPath.FORWARD_CLOSED_ARROW,
        fillColor: "white",
        fillOpacity: 1,
        scale: 7,
        strokeColor: "white",
        strokeWeight: 3,
      };

      _routePathLine.current = new _MAPS.current.Polyline({
        path: _pathsData.current,
        icons: [
          {
            icon: circleicon,
            offset: "0%",
          },
          {
            icon: arrowicon,
            offset: "15px",
          },
        ],
        strokeColor: "#fff",
        strokeOpacity: 0.4,
        strokeWeight: Math.max(7, _lineStrokeWidth.current - 8),
        zIndex: 1,
      });

      _routePathOutLine.current = new _MAPS.current.Polyline({
        path: _pathsData.current,
        strokeColor: "#1a5ae2",
        strokeOpacity: 0.8,
        strokeWeight: Math.max(9, _lineStrokeWidth.current),
        zIndex: 0,
      });

      _routePathLine.current.setMap(_MAP.current);
      _routePathOutLine.current.setMap(_MAP.current);

      _MAP.current.setCenter(_pathsData.current[0]);

      const bounds = new _MAPS.current.LatLngBounds();
      bounds.extend(_pathsData.current[0]);
      bounds.extend(_pathsData.current[1]);
      var bearing = calculateBearing(
        _pathsData.current[0].lat,
        _pathsData.current[0].lng,
        _pathsData.current[1].lat,
        _pathsData.current[1].lng
      );
      // console.log("bearing ", bearing);
      // if (bearing == 0) {
      //   bearing = 90;
      // }
      _MAP.current.setHeading(bearing);

      // Get instruction
      const [curStep, nextStep, distance, estTime, estDist] = getCurStepInfo(
        _stepsData.current,
        _pathsData.current[0]
      );
      if (nextStep != null) {
        const arrowIndicatorIcon = {
          path: _MAPS.current.SymbolPath.FORWARD_CLOSED_ARROW,
          fillColor: "white",
          fillOpacity: 1,
          scale: 4,
          strokeColor: "white",
          strokeWeight: 6,
        };

        let indicatorPath = [];
        if (curStep.lat_lngs?.length > 1) {
          indicatorPath = curStep.lat_lngs.slice(
            curStep.lat_lngs?.length - 2,
            curStep.lat_lngs?.length
          );
        }
        if (nextStep.lat_lngs?.length > 1) {
          indicatorPath = [...indicatorPath, ...nextStep.lat_lngs.slice(0, 2)];
        }
        _routeIndicator.current = new _MAPS.current.Polyline({
          path: indicatorPath,
          icons: [
            {
              icon: arrowIndicatorIcon,
              offset: "100%",
            },
          ],
          strokeColor: "#fff",
          strokeOpacity: 1,
          strokeWeight: 5,
          zIndex: 1,
        });
        _routeIndicator.current.setMap(_MAP.current);

        dispatch(
          setTrackData({
            curStep,
            nextStep,
            distance,
            orientation: getDirection(curStep, nextStep),
            estTime,
            estDist,
          })
        );
      } else {
        dispatch(setTrackData(null));
      }
    }
  };

  // console.log("HERO MAP LOADED !!!!!!!!!!!!!!!!!!");
  if (userOldLoc.current.lat === null || userOldLoc.current.lng === null) {
    return null;
  }

  return (
    <div className="w-full flex-1 relative" ref={mapView}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: GOOGLE_MAP_API_KEY,
          libraries: ["places", "geometry", "drawing"],
        }}
        defaultCenter={[
          parseFloat(userOldLoc.current.lat),
          parseFloat(userOldLoc.current.lng),
        ]}
        defaultZoom={14}
        options={{
          tilt: 45,
          maxZoom: 19,
          // heading: 90,
          mapId: "7d8f9c62a3df4abc",
          // mapTypeId: 'satellite',
          // mapTypeId: 'roadmap',
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: true,
          fullscreenControl: false,
        }}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        <LocationMarker
          lat={parseFloat(activeJob?.latitude)}
          lng={parseFloat(activeJob?.longitude)}
        />
        {/* {viewMode === 'overview' ? (
          <PersonMarker
            gender={user.gender}
            lat={userOldLoc.current.lat}
            lng={userOldLoc.current.lng}
          />
        ) : (
          <div />
        )} */}
      </GoogleMapReact>
      {activeJob?.customer_coming && (
        <div
          className="w-full text-center absolute top-9 text-[15px] text-primary2 font-semibold "
          style={{ top: "30px" }}
        >
          Customer is coming
        </div>
      )}
      <MapInstruction />
      <Estimation />
      <FloatButton.Group
        trigger="click"
        style={{
          insetInlineEnd: 24,
          position: "absolute",
          right: 30,
        }}
        className={`heromap-float-btn ${"active"}`}
        open={isRouteBtnActive}
        icon={
          viewMode === "walk" ? (
            <FaWalking className="text-white" />
          ) : viewMode === "car" ? (
            <FaCarSide className="text-white" />
          ) : (
            <FaRoute className="text-white" />
          )
        }
        closeIcon={
          viewMode === "walk" ? (
            <FaWalking className="text-white" />
          ) : viewMode === "car" ? (
            <FaCarSide className="text-white" />
          ) : (
            <FaRoute className="text-white" />
          )
        }
        onOpenChange={setIsRouteBtnActive}
        onClick={() => {
          setIsRouteBtnActive((pre) => !pre);
        }}
      >
        {["walk", "car", 'overview'].filter(item => item != viewMode).map((item, index) => (
          <FloatButton
            key={index}
            className={`heromap-walk-btn inactive `}
            icon={
              item === "walk" ? (
                <FaWalking className="text-white" />
              ) : item === "car" ? (
                <FaCarSide className="text-white" />
              ) : (
                <FaRoute className="text-white" />
              )
            }
            onClick={() => {
              setViewMode(item);
              mapView.current?.click();
            }}
          />
        ))}
      </FloatButton.Group>
    </div>
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  return true;
};

export default React.memo(HeroMap, arePropsEqual);
