import moment from "moment";
import { isMobile } from "react-device-detect";
import RouteNames from "../constants/route_names";

export const isEmpty = (string) => {
  if (string != null && string.length > 0) {
    return false;
  }
  return true;
};

export const ucFirst = (str) => {
  if (str == null || str === "") {
    return "";
  }
  return str
    ?.split(" ")
    ?.map((s) => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase())
    ?.join(" ");
};

export function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  let value = `${year}-${month}-${day}`;
  return { value: value, label: moment(date).format('DD MMMM') };
}

export function generateDateArray() {
  const numDays = 90;
  const dateArray = [];
  const today = new Date();

  for (let i = 0; i < numDays; i++) {
    const currentDate = new Date(today);
    currentDate.setDate(today.getDate() + i);
    dateArray.push(formatDate(currentDate));
  }

  return dateArray;
}

export function formatTime(hour, minute) {
  const period = hour >= 12 ? "PM" : "AM";
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
  const formattedMinute = String(minute).padStart(2, "0");
  const time = `${formattedHour}:${formattedMinute} ${period}`;
  return { value: time, label: time };
}

export function generateTimeArray() {
  const timeArray = [];

  for (let hour = 7; hour <= 22; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      timeArray.push(formatTime(hour, minute));
    }
  }

  return timeArray;
}

export function getUtcTimeIntervalsWithDuration(start, end, intervalMinutes, durationHours) {
  const startTime = new Date(`1970-01-01T${start}Z`);
  const endTime = new Date(`1970-01-01T${end}Z`);
  const intervals = [];

  // Calculate the interval duration in milliseconds
  const intervalDuration = intervalMinutes * 60 * 1000;
  const durationMilliseconds = durationHours * 60 * 60 * 1000;

  for (let t = startTime; t <= endTime; t.setMinutes(t.getMinutes() + intervalMinutes)) {
    const formattedStart = t.toISOString().substr(11, 8); // Start time in HH:MM:SS format (UTC)

    const formattedEnd = new Date(t.getTime() + durationMilliseconds)// End time
    if (formattedEnd < endTime) {
      let splits = formattedStart.split(':');
      intervals.push(formatTime(splits[0], splits[1]));
    }
  }

  return intervals;
}

export const createUUID = () => {
  let s = [];
  let hexDigits = "0123456789ABCDEF";
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  let uuid = s.join("");
  return uuid;
};

export const calculateWordCount = (currentBlog) => {
  if (currentBlog) {
    const countWords = (text) =>
      (text?.split(/\s+/) ?? [])?.filter((word) => word.length > 0)?.length;

    let totalWordCount = countWords(currentBlog.introduction);

    for (let i = 1; i <= 8; i++) {
      const sectionTitle = currentBlog[`section_${i}_title`] || "";
      const sectionContent = currentBlog[`section_${i}_content`] || "";

      totalWordCount += countWords(sectionTitle) + countWords(sectionContent);
    }

    return totalWordCount;
  }
  return 0;
};

export const modalButtonProps = {
  okButtonProps: {
    style: {
      background:
        "linear-gradient(128deg, rgb(36, 11, 59) 3.95%, rgb(61, 30, 89) 34%, rgb(51, 5, 94) 67.3%, rgb(188, 20, 254) 94.28%)",
      color: "white",
      height: "40px",
      width: isMobile ? "100%" : "50%",
      marginLeft: isMobile ? "0px" : "8px",
      marginTop: isMobile ? "15px" : "0",
    },
  },
  cancelButtonProps: {
    style: {
      backgroundColor: "transparent",
      color: "#240b3b",
      borderColor: "#CBD5E1",
      height: "40px",
      width: isMobile ? "100%" : "50%",
    },
  },
};

export const formatAddress = (addr) => {
  if (addr == null) {return ''};

  let address = '';
  if (!isEmpty(addr.address_line2)) {
    address = address + addr.address_line2 + ',';
  }
  if (!isEmpty(addr.address_line1)) {
    address = address + addr.address_line1 + ',';
  }
  if (!isEmpty(addr.city)) {
    address = address + addr.city + ',';
  }
  if (!isEmpty(addr.country)) {
    address = address + addr.country;
  }
  return address;
}

export const extractCityInfo = (addressComp) => {
  let address_line2 = '';
  let address_line1 = '';
  let city = '';
  let country = '';
  for (let i = 0; i < addressComp.length; i++) {
    if (addressComp[i].types.includes('sublocality_level_1') || addressComp[i].types.includes('sublocality') || addressComp[i].types.includes('premise')) {
      address_line2 = addressComp[i].long_name;
    }
    if (addressComp[i].types.includes('administrative_area_level_1') || addressComp[i].types.includes('neighborhood') || addressComp[i].types.includes('route')) {
      address_line1 = addressComp[i].long_name;
    }
    // city
    if (addressComp[i].types.includes('locality') || addressComp[i].types.includes('postal_town')) {
      city = addressComp[i].long_name;
    }
    // country
    if (addressComp[i].types.includes('country')) {
      country = addressComp[i].long_name;
    }
  }

  return {
    address_line1,
    address_line2,
    city,
    country
  };
};

export function removeHtmlTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  return str.replace(/(<([^>]+)>)/gi, "");
}

export const formatDistance = (distance, isFullUnit = false) => {
  if (distance == null) {return ''}
  let dist = parseFloat(distance);
  if (dist >= 1000) {
    return parseFloat(dist / 1000).toFixed(2) + (isFullUnit ? 'kilometers' : 'km')
  }
  return parseInt(dist) + (isFullUnit ? 'meters' : 'm')
}

export const getMinutesDiff = (date) => {
  const specificDate = moment(date).format('YYYY MM DD hh:mm:ss');
  const now = moment().utc().format('YYYY MM DD hh:mm:ss');
  
  // Calculate the difference in seconds
  const secondsDiff = moment(now).diff(moment(specificDate), 'seconds');
  
  console.log(specificDate , now , secondsDiff)

  const dispSeconds = 5 * 60 - secondsDiff;
  if (dispSeconds <= 0) {
    return null;
  }

  const minutes = Math.floor(Math.abs(dispSeconds) / 60);
  const seconds = Math.abs(dispSeconds) % 60;
  
  // Format in mm:ss
  const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  
  console.log(formattedTime)
  return formattedTime;
}