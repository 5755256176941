import React, { useEffect, useState } from "react";
import { MdStar } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { formatDistance } from "../../utils/common";
import Avatar from "../Avatar";

const BidItem = ({ job, bid, onDecline, onAccept }) => {

  return (
    <div className='w-full p-2 bg-white rounded-[8px] mt-3'>
      <div className='w-full flex justify-start items-start'>
        <Avatar image={bid?.hero?.photo}/>
        <div className='flex-1 mx-3'>
          <p>{bid?.hero?.name}</p>
          <p>{bid?.hero?.user_name}</p>
          <div className="flex justify-start items-center">
            <MdStar color="#ffff00" size={13} />
            <span className="text-[11px] text-[#000] font-semibold">{parseFloat(bid?.hero?.rating).toFixed(1)} ({bid?.hero?.rating_cnt})</span>
          </div>
        </div>
        <div className='flex flex-col justify-between items-end'>
          <h2 className={`text-[20px] font-bold ${bid?.price == job?.price ? 'text-[#0f0]' : 'text-[#000]'}`}>RM {parseInt(bid?.price)}</h2>
          <p className='text-[14px] text-[#000]'>{formatDistance(bid?.distance)}</p>
        </div>
      </div>
      <div className='mt-4 w-full grid grid-cols-2 gap-3'>
        <button className='h-[38px] text-[15px] text-[#f00] font-semibold rounded-full bg-white border border-primary2'
          onClick={() => onDecline(bid)}
        >
          Decline
        </button>
        <button className='h-[38px] text-[15px] text-white font-semibold rounded-full bg-primary2 border border-primary2'
          onClick={() => onAccept(bid)}
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default BidItem;


