import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveJob } from "../../redux/actions/app";

export default function UpdateActiveJob() {
    const dispatch = useDispatch();
    const _timer = useRef(null);
    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        if (_timer.current != null) {
            clearInterval(_timer.current);
        }
        _timer.current = setInterval(function () {
            try {
                if (user) {
                    dispatch(getActiveJob());
                }
            } catch (error) { }
        }, 5000);

        try {
            if (user) {
                dispatch(getActiveJob());
            }
        } catch (error) { }

        return () => {
            if (_timer.current != null) {
                clearInterval(_timer.current);
            }
        }
    }, [user])

    return null;
}