import React, { useEffect, useState } from "react";
import { FiMenu, FiSettings } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Switch from "../../components/Switch/Switch";
import { setShowSidebar } from "../../redux/actions/app";

const HeroHeader = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [status, setStatus] = useState('offline')

    return (
        <div className="w-full flex justify-center items-center px-4 py-3 mb-[1px] bg-white-primary1 drop-shadow">
            <div className=" flex justify-center items-center">
                <FiMenu size={20} className='text-primary1-white cursor-pointer' onClick={() => dispatch(setShowSidebar(true))} />
            </div>
            <div className="flex-1 flex justify-center items-center">
                <Switch value={status} onChangeValue={setStatus} />
            </div>
            <div className=" flex justify-center items-center">
                <FiSettings size={20} className='text-primary1-white  cursor-pointer' onClick={() => navigate('/hero/job-settings')} />
            </div>
        </div>
    );
};

export default HeroHeader;


