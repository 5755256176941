import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setShowSidebar } from "../../redux/actions/app";

export const SidebarLink = ({
  href,
  icon,
  label,
  onItemClick
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(setShowSidebar(false));
      if (onItemClick) {
        onItemClick(e);
      } else {
        navigate(href);
      }
    },
    [navigate, href, onItemClick]
  );

  return (
    <div
      className={`w-full cursor-pointer px-5 py-3`}
      onClick={handleClick}
    >
      <div className="flex items-center gap-2">
        <span>{icon}</span>
        <p className="ml-2 text-primary1-white text-[15px] font-semibold">{label}</p>
      </div>
    </div>
  );
};
