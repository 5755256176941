import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import JobService from '../../services/JobService';
import { NotificationManager } from "react-notifications";
import CancelJobModal from '../../components/CancelJobModal';
import { formatDistance, isEmpty } from '../../utils/common';
import BidItem from '../../components/BidItem';

const JobBidList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { job_id } = useParams();
    const { state } = useLocation();
    const _timer = useRef(null);
    const [jobData, setJobData] = useState(state.job);
    const { user } = useSelector(state => state.auth)
    const [loading, setLoading] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [bids, setBids] = useState([]);

    useEffect(() => {
        setJobData(state.job);
    }, [state])

    useEffect(() => {
        if (_timer.current != null) {
            clearInterval(_timer.current);
        }
        _timer.current = setInterval(function () {
            refreshData();
        }, 5000);
        refreshData();

        return () => {
            if (_timer.current != null) {
                clearInterval(_timer.current);
            }
        }
    }, [])

    const refreshData = () => {
        setLoading(true);
        JobService.getAllBids()
            .then((res) => {
                setBids(res.bids || []);
                console.log('bids ', res)
                setLoading(false);
            }).catch(e => {
                console.log(e)
                setLoading(false);
                NotificationManager.error(
                    e?.message ||
                    "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                    "Error",
                    3000
                );
            });
    }

    const handleCancelJob = (reason) => {
        setLoading(true);
        JobService.cancelJob({
            job_id: job_id,
            reason: reason
        }).then((res) => {
            console.log('cancel job ', res)
            setLoading(false);
            navigate('/customer');
        }).catch(e => {
            console.log(e)
            setLoading(false);
            NotificationManager.error(
                e?.message ||
                "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                "Error",
                3000
            );
        });
    }

    const acceptBid = (bid) => {
        setLoading(true);
        JobService.acceptDeclineBid({
            job_id: job_id,
            bid_id: bid.id,
            bid_status: 'accepted'
        }).then((res) => {
            console.log('placeBid ', res)
            setLoading(false);
            navigate(`/customer/accepted-job/${job_id}`, {state: {job: jobData}});
        }).catch(e => {
            console.log(e)
            setLoading(false);
            NotificationManager.error(
                e?.message ||
                "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                "Error",
                3000
            );
        });
    }

    const declineBid = (bid) => {
        setLoading(true);
        JobService.acceptDeclineBid({
            job_id: job_id,
            bid_id: bid.id,
            bid_status: 'rejected'
        }).then((res) => {
            console.log('placeBid ', res)
            setLoading(false);
        }).catch(e => {
            console.log(e)
            setLoading(false);
            NotificationManager.error(
                e?.message ||
                "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                "Error",
                3000
            );
        });
    }

    return (
        <div className='w-full h-full bg-white-primary1 flex flex-col justify-center items-center'>
            <div className='w-full flex justify-center items-center h-[50px] px-4'>
                <div className='w-[40px]' />
                <p className='flex-1 text-[16px] leading-[21px] text-primary1-white font-bold text-center'>Heros</p>
                <div className='text-[14px] leading-[21px] text-primary2 font-bold text-center' onClick={()=>setShowCancelModal(true)}>
                    Cancel
                </div>
            </div>
            <div className="w-full px-4 py-2 flex flex-col justify-start items-start bg-gray5-primary2">
                <p className="w-full text-[18px] leading-[24px] text-primary1-white font-bold">{jobData?.title}</p>
                <p className="mt-3 w-full text-[15px] leading-[21px] text-[#f00] font-bold text-right">RM {parseInt(jobData?.price)}</p>
            </div>
            <div className='w-full flex-1 overflow-y-auto bg-gray6-primary1 p-3'>
                <p className="mb-3 w-full text-[15px] leading-[21px] text-primary1-white font-bold text-center">
                    You can accept or decline
                </p>
                {
                    bids.map(bid =>
                        <BidItem key={bid.id} job={jobData} bid={bid} onDecline={declineBid} onAccept={acceptBid} />
                    )
                }
            </div>
            {
                showCancelModal &&
                <CancelJobModal
                    showModal={showCancelModal}
                    onClose={(data) => {
                        setShowCancelModal(false);
                        if (!isEmpty(data)) {
                            handleCancelJob(data);
                        }
                    }}
                />
            }
        </div>
    )
}

export default JobBidList;