import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { extractCityInfo, formatAddress, isEmpty } from '../../utils/common';
import { FaMale, FaFemale, FaQuestionCircle } from "react-icons/fa";
import { BiMaleFemale } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineSupportAgent } from "react-icons/md";
import SupportModal from '../../components/SupportModal';
import { currencies, GOOGLE_MAP_API_KEY, TaskExamples } from '../../constants/config';
import { useDispatch, useSelector } from 'react-redux';
import { getAddressByCoordinates } from '../../utils/location';
import LocationMarker from '../../components/Markers/LocationMarker';
import HelpSamples from '../../components/HelpSamples';
import ImgSupport from '../../assets/images/test/support.png';
import LocationInput from '../../components/LocationInput';
import { Input, Space, Select, Spin } from 'antd';
import JobService from '../../services/JobService';
import { NotificationManager } from "react-notifications";
import RateModal from '../../components/RateModal';
import { getActiveJob, setShowSidebar } from '../../redux/actions/app';
import { MdOutlineCleaningServices } from "react-icons/md";
import { BsUmbrella } from "react-icons/bs";
import { TbCat } from "react-icons/tb";
import { GiNotebook } from "react-icons/gi";
import { IoIosFitness } from "react-icons/io";


const JobTemplates = [
    { id: 1, img: <MdOutlineCleaningServices className='text-white' size={28} />, text: 'Clean my toilet.' },
    { id: 2, img: <BsUmbrella className='text-white' size={28}/>, text: 'Take umbrella cover me cross a street.' },
    { id: 3, img: <GiNotebook className='text-white' size={28}/>, text: 'Finish my work urgent.' },
    { id: 4, img: <TbCat className='text-white' size={28}/>, text: 'Cure my sick cat urgent, it sick so serious.' },
    { id: 5, img: <IoIosFitness className='text-white' size={28}/>, text: 'Accompany me to the gym' },
];

const HeroTypeTab = ({ data, isSelected, onSelect }) => {
    return (
        <div className={`px-2 py-1 rounded-[6px] ${isSelected ? 'bg-gray5-primary2' : 'bg-white-primary1'}  flex justify-center items-center`}
            onClick={() => onSelect(data)}
        >
            {data?.img}
            <span className='ml-2 text-primary1-white text-[14px] font-semibold'>{data?.label}</span>
        </div>
    )
}

const CustomerHome = () => {
    const navigate = useNavigate();
    const _timer = useRef(null);
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth)
    const [loading, setLoading] = useState(false);
    const [messagePlaceholderId, setMessagePlaceholderId] = useState(0);
    const [message, setMessage] = useState('');
    const [price, setPrice] = useState();
    const [showSupportModal, setShowSupportModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [curLoc, setCurLoc] = useState({});
    const [heroGender, setHeroGender] = useState(-1);
    const heroGendersData = [
        { id: -1, img: <BiMaleFemale size={36} className={'text-black3-gray1'} />, label: 'Any' },
        { id: 1, img: <FaFemale size={36} className={'text-black3-gray1'} />, label: 'Girl' },
        { id: 0, img: <FaMale size={36} className={'text-black3-gray1'} />, label: 'Boy' },
    ]
    const [showRateModal, setShowRateModal] = useState(false);
    const [completedJob, setCompletedJob] = useState(null);

    useEffect(() => {
        setCurLoc({
            latitude: user?.cur_latitude,
            longitude: user?.cur_longitude,
            country: user?.address?.country,
            city: user?.address?.city,
            address_line1: user?.address?.address_line1,
            address_line2: user?.address?.address_line2,
        })
    }, [user])

    useEffect(() => {
        if (_timer.current != null) {
            clearInterval(_timer.current);
        }
        _timer.current = setInterval(function () {
            setMessagePlaceholderId((pre) => pre == TaskExamples.length ? 0 : pre + 1)
        }, 1500);

        loadUnratedJob();

        return () => {
            if (_timer.current != null) {
                clearInterval(_timer.current);
            }
        }
    }, [])

    const loadUnratedJob = () => {
        JobService.getCustomerUnRatedJob()
            .then((res) => {
                if (res.job != null) {
                    setCompletedJob(res.job);
                    setShowRateModal(true);
                }
            }).catch(e => {
            });
    }

    const _changeMarkerPosition = async (latitude, longitude) => {
        try {
            const address = await getAddressByCoordinates({
                latitude: latitude,
                longitude: longitude
            });
            if (address) {
                setCurLoc(address);
            }
            // eslint-disable-next-line no-empty
        } catch (error) {
            console.log('_changeMarkerPosition', error)
        }
    };

    const _changeLocPlace = useCallback((place) => {
        let data = extractCityInfo(place.address_components);
        setCurLoc({
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
            address_line1: data.address_line1,
            address_line2: data.address_line2,
            country: data.country,
            city: data.city
        })
    }, [])

    const onSubmit = async () => {
        try {
            setLoading(true);
            await JobService.createJob({
                title: message,
                price: price,
                currency: 'rm',
                gender: heroGender,
                ...curLoc,
            });
            await dispatch(getActiveJob());
            setLoading(false);
            navigate(`/customer/wait-hero`);
        }
        catch (e) {
            setLoading(false);
            NotificationManager.error(
                e?.message ||
                "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                "Error",
                3000
            );
        }
    }

    return (
        <div className='w-full h-full bg-white-primary1 flex flex-col justify-center items-center'>
            <div className='w-full h-[50%] relative'>
                {
                    curLoc.latitude != null && curLoc.longitude != null &&
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY, libraries: ['places', 'geometry'] }}
                        center={[parseFloat(curLoc.latitude), parseFloat(curLoc.longitude)]}
                        defaultCenter={[parseFloat(curLoc.latitude), parseFloat(curLoc.longitude)]}
                        defaultZoom={12}
                        options={{
                            zoomControl: false,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                        }}
                        onClick={(e) => {
                            console.log(e)
                            _changeMarkerPosition(e.lat, e.lng);
                        }}>
                        <LocationMarker lat={parseFloat(curLoc.latitude)} lng={parseFloat(curLoc.longitude)} />
                    </GoogleMapReact>
                }
                <div className='w-[38px] h-[38px] rounded-full absolute top-7 left-6 drop-shadow bg-white-primary1 flex justify-center items-center'
                    onClick={() => {
                        dispatch(setShowSidebar(true))
                    }}
                >
                    <GiHamburgerMenu fontSize={24} className='text-primary1-white' />
                </div>
            </div>
            <div className='flex-1 p-4 w-full rounded-tl-[4px] rounded-tr-[4px] bg-white-primary1 drop-shadow'>
                <div className='flex justify-start items-center'>
                    {heroGendersData.map(ut =>
                        <HeroTypeTab key={ut.id} data={ut} isSelected={heroGender == ut.id} onSelect={(d) => setHeroGender(d.id)} />
                    )}
                </div>
                <div className='mt-4 flex justify-start items-center'>
                    <p className='text-primary1-white text-[15px] font-bold'>You help me to : </p>
                    <div className="w-[20px] h-[20px] flex justify-center items-center"
                        onClick={() => {
                            setShowHelpModal(true)
                        }}
                    >
                        <FaQuestionCircle size={18} className="ml-1 text-black2-gray3 " />
                    </div>
                </div>
                <textarea placeholder={TaskExamples[messagePlaceholderId]} value={message} onChange={(e) => setMessage(e.target.value)} className="mt-3 w-full rounded p-3 min-h-[42px] font-medium text-[14px] text-primary1 leading-[19px] bg-[#f5f5f5]">
                </textarea>
                <div className='w-full flex justify-center items-center'>
                    <div className=' grid grid-cols-5 gap-5'>
                        {
                            JobTemplates.map(template =>
                                <div key={template.id} className='w-[38px] h-[38px] rounded-[6px] bg-primary2 flex justify-center items-center'
                                    onClick={() => setMessage(template.text)}
                                >
                                    {template.img} 
                                </div>
                            )
                        }
                    </div>
                </div>
                <LocationInput placeholder={'Your location'} defaultInput={formatAddress(curLoc)} onChangePlace={_changeLocPlace} />
                <Input placeholder={'Offer your price'}
                    type='number'
                    className='mt-4 p-3 w-full h-[42px] rounded bg-[#f5f5f5]'
                    prefix={<span className='font-bold text-[#333]'>RM</span>}
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                />
                <div className='w-full mt-4 flex justify-center items-center'>
                    {loading ?
                        <div className='flex-1 h-[42px] flex justify-center items-center'>
                            <Spin />
                        </div>
                        :
                        <button className='flex-1 h-[42px] rounded-[4px] bg-primary2 text-white text-[14px] font-semibold'
                            onClick={onSubmit}
                        >Find a Hero</button>
                    }
                    <button className='ml-4 flex h-[42px] w-[42px] bg-primary2 rounded-[4px] justify-center items-center'
                        onClick={() => setShowSupportModal(true)}
                    >
                        <MdOutlineSupportAgent size={22} className={'text-white'} />
                    </button>
                </div>
            </div>
            {
                showSupportModal &&
                <SupportModal
                    showModal={showSupportModal}
                    onClose={() => setShowSupportModal(false)}
                />
            }
            {
                showHelpModal &&
                <HelpSamples showModal={showHelpModal} onClose={(data) => {
                    if (!isEmpty(data)) {
                        setMessage(data)
                    }
                    setShowHelpModal(false);
                }} />
            }
            {
                showRateModal &&
                <RateModal
                    title={'Rate your Hero'}
                    showModal={showRateModal}
                    job={completedJob}
                    onClose={() => setShowRateModal(false)}
                />
            }
        </div>
    )
}

export default CustomerHome;