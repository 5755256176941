import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


const Wallet = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { job_id } = useParams();
    const { state } = useLocation();
    const { activeJob } = useSelector(state => state.app);
    const { user } = useSelector(state => state.auth)

    return (
        <div className='w-full h-full  flex flex-col justify-center items-center'>
            
        </div>
    )
}

export default Wallet;