import React, { useEffect, useState } from "react";
import { MdStar } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { RiSpamFill } from "react-icons/ri";
import { IoMdEyeOff } from "react-icons/io";
import { formatDistance } from "../../utils/common";
import Avatar from "../Avatar";

const JobItem = ({ data, onReportSpam, onHide, onClick }) => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex p-3 justify-center items-center bg-white-primary1 border-b border-b-[#444]" onClick={onClick}>
      <div className="mr-4 flex flex-col justify-center items-center">
        <Avatar image={data?.customer?.photo} />
        <p className="mt-1 text-[12px] text-primary1-white font-bold">{data?.customer?.name}</p>
        <div className="flex justify-center items-center">
          <MdStar color="#ffff00" size={13} />
          <span className="text-[11px] text-primary1-white font-semibold">{parseFloat(data?.customer?.rating).toFixed(1)} ({data?.customer?.rating_cnt})</span>
        </div>
        <p className="text-[12px] text-black2-gray3 font-semibold">{data?.customer?.response_time}</p>
      </div>
      <div className="h-full flex flex-1 flex-col justify-start items-start">
        <div>
          <p className="w-full text-[18px] leading-[24px] text-primary1-white font-bold">{data?.title}</p>
        </div>
        <div className="flex-1 flex w-full justify-between items-end">
          <div className="flex justify-center items-center">
            <span className="text-[14px] text-[#f00] font-semibold">RM{parseInt(data?.price)}</span>
            <span className="text-[14px] text-primary1-white font-semibold"> ~ {formatDistance(data?.distance)}</span>
          </div>
          <div className="flex justify-center items-center">
            <div className="flex justify-center items-center mr-4">
              <RiSpamFill size={24} className="text-black3-gray1" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onReportSpam();
              }} />
            </div>
            <div className="flex justify-center items-center">
              <IoMdEyeOff size={28} className="text-black3-gray1" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onHide();
              }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobItem;


