import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft, FiChevronRight } from "react-icons/fi";
import ConfigService from "../../services/ConfigService";

const AboutUs = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState('');

    useEffect(() => {
        //loadData();
    }, [])

    const loadData = () => {
        ConfigService.getConfig()
            .then((res) => {
                setData(res.data?.safety || '');
            }).catch(e => {
            });
    }
    return (
        <div className='w-full h-full  bg-white-primary1 flex flex-col justify-center items-center'>
            <div className="w-full flex justify-start items-center  py-4 bg-white-primary1 drop-shadow">
                <div className="w-[50px] flex justify-center items-center">
                    <FiArrowLeft size={20} className='text-primary1-white cursor-pointer' onClick={() => navigate(-1)} />
                </div>
                <div className=" flex-1 text-center text-[16px]  text-primary1-white font-bold ml-3">About Us</div>
                <div className="w-[50px] flex justify-center items-center"></div>
            </div>
            <div className=' flex-1 w-full flex flex-col px-5 justify-start items-center overflow-y-auto'> 
            <p className="mt-5 w-full text-[14px] leading-[22px] text-black2-gray3">
                    <b>Heromay</b> is a marketplace where customers request service and freelancer provide service.<br/>

                    We connect employers and freelancers globally from over 247 countries, regions, and territories.<br/>

                    Through our marketplace, employers can hire freelancers to do work in areas such as software development, writing, data entry and design right through to engineering, the sciences, sales and marketing, accounting and legal services.<br/>

                    Get your projects moving faster with freelancers tru Heromay platform.<br/><br/>

                    <b>Heromay Enterprise (003644461-D)</b><br/>

                    <b>Phone:</b> +6010 -511 9146<br/>
                    <b>Email:</b> admin@heromay.com<br/><br/>

                    <b>Business hour:</b><br/>
                    Mon-Fri: 9am-6pm<br/><br/>

                    <b>Address:</b><br/>
                    UNIT 0912. BLOCK A2.<br/>
                    PARKLANE OUG SERVICE APARTMENT.<br/>
                    NO.1 JALAN 1/152.<br/>
                    TAMAN OUG PARKLANE.<br/>
                    58200 KUALA LUMPUR.<br/>
                    Malaysia. 
                    <br/><br/>
                </p>
            </div>
        </div>
    );
};

export default AboutUs;
