import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import JobService from "../../services/JobService";
import { getMinutesDiff } from "../../utils/common";
import { NotificationManager } from "react-notifications";

const HeroArrivedNoti = () => {
  const { activeJob } = useSelector(state => state.app);
  const _timer = useRef(null);
  const [time, setTime] = useState(null);

  useEffect(() => {
    if (_timer.current != null) {
      clearInterval(_timer.current);
    }
    _timer.current = setInterval(function () {
      if (activeJob?.hero_arrived_at != null) {
        setTime(getMinutesDiff(activeJob?.hero_arrived_at))
      }
    }, 1000);

    return () => {
      if (_timer.current != null) {
        clearInterval(_timer.current);
      }
    }
  }, [activeJob?.hero_arrived_at])

  if (activeJob?.customer_coming || time == null) {
    return null;
  }

  const onSetComing = async () => {
    try {
      await JobService.updateCustomerComing({ job_id: activeJob.id})
      if (_timer.current != null) {
        clearInterval(_timer.current);
      }
      setTime(null)
    } catch (error) {
      NotificationManager.error(
        error?.message ||
        "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
        "Error",
        3000
      );
    }
  }

  const onCancel = () => {
    if (_timer.current != null) {
      clearInterval(_timer.current);
    }
    setTime(null)
  }

  return (
    <div className='w-full rounded-[12px] p-3 bg-[#323943] mt-3'>
      <div className='w-full flex justify-between items-center'>
        <p className='text-[12px] text-[#aaa]'>Please don't be late, it may <br />affect your rating</p>
        <h2 className='text-[24px] text-white font-bold'>{time}</h2>
      </div>
      <div className='mt-3 w-full grid grid-cols-2 gap-3'>
        <button className='text-[14px] text-white font-semibold bg-primary2 rounded-[8px] h-[38px]'
          onClick={onSetComing}
        >
          Ok, I'm coming
        </button>
        <button className='text-[14px] text-white font-semibold bg-[#f00] rounded-[8px] h-[38px]'
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default HeroArrivedNoti;


