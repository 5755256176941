import React, { useEffect, useState } from "react";
import { Drawer, Spin } from 'antd';
import { IoClose } from "react-icons/io5";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { GOOGLE_MAP_API_KEY, TaskExamples } from "../../constants/config";
import './index.css'

const HelpSamples = ({ showModal, onClose }) => {
    const [open, setOpen] = useState(false); 

    useEffect(() => {
        setOpen(showModal)
    }, [showModal])

    return (
        <Drawer
            placement={'bottom'}
            closable={false}
            onClose={() => onClose()}
            open={open}
            rootClassName="w-full max-w-md mx-auto"
            className="choose-location-modal"
            height={'84vh'}
        >
            <div className="bg-primary1 ">
                <div className="w-full flex justify-center items-center">
                    <div className="w-[32px]" />
                    <p className="flex-1 text-center text-white text-[20px] font-bold">Task Examples</p>
                    <div className="w-[32px] h-[32px] flex justify-center items-center bg-[#777] rounded-full" onClick={() => onClose()}>
                        <IoClose size={24} color="#fff" />
                    </div>
                </div>
                <div className="w-full mt-4">
                    {
                        TaskExamples.map((item, index) =>
                            <div key={index} className="w-full py-2 border-b border-b-[#555]" onClick={() => onClose(item)}>
                                <p className="text-[14px] text-[#bbb] font-semibold">{item}</p>
                            </div>
                        )
                    }
                </div>
            </div>
        </Drawer>
    );
};

export default HelpSamples;


