import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft, FiChevronRight } from "react-icons/fi";
import ConfigService from "../../services/ConfigService";

const Faqs = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        ConfigService.getFaqs()
            .then((res) => {
                setFaqs(res.data || []);
            }).catch(e => {
            });
    }

    return (
        <div className='w-full h-full  bg-white-primary1 flex flex-col justify-center items-center'>
            <div className="w-full flex justify-start items-center  py-4 bg-white-primary1 drop-shadow">
                <div className="w-[50px] flex justify-center items-center">
                    <FiArrowLeft size={20} className='text-primary1-white' onClick={() => navigate(-1)} />
                </div>
                <div className=" flex-1 text-center text-[16px] text-primary1-white font-bold ml-3">FAQ</div>
                <div className="w-[50px] flex justify-center items-center"></div>
            </div>
            <div className='my-5 flex-1 w-full flex flex-col px-5 justify-start items-center overflow-y-auto'>
                {
                    faqs.map((qa, id) =>
                        <div key={id} className="w-full mt-4">
                            <p
                                className="w-full text-[16px] font-semibold leading-[22px] text-primary1-white"
                            >{qa.question}</p>
                            <p className="mt-2 w-full text-[14px] leading-[22px] text-black2-gray3">
                                {qa.answer}
                            </p>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default Faqs;
