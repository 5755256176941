import React, { useEffect, useState } from 'react';
import Svg_male from '../../assets/svgs/markers/male.svg';
import Svg_female from '../../assets/svgs/markers/female.svg';
import { GENDER_TYPE } from '../../constants/config';

const PersonMarker = ({
  user_id,
  gender,
  user
}) => {
  return (
    <div className={'map-marker snapfooder-marker'}
      style={{ transform: 'translate(-50%, -50%)' }}
    >
      <div className={'marker-body'}>
        <img
          src={gender === GENDER_TYPE.female ? Svg_female : Svg_male}
        />
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.user_id === nextProps.user_id &&
    prevProps.gender === nextProps.gender &&
    prevProps.lat === nextProps.lat &&
    prevProps.lng === nextProps.lng
  );
}

export default React.memo(PersonMarker, arePropsEqual);
