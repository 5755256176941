import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import JobService from "../../services/JobService";
import { NotificationManager } from "react-notifications";

const JobSpamReportModal = ({ job, showModal, onClose }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    setOpen(showModal);
  }, [showModal])

  const onSubmit = () => {
    setLoading(true);
    JobService.reportSpamJob({ job_id: job?.id, message: message })
      .then((res) => {
        setLoading(false);
        console.log('reportSpamJob ', res)
        NotificationManager.success(
          "The Job was reported as a spam",
          "Success",
          3000
        );
        onClose(true)
      }).catch(e => {
        setLoading(false);
        console.log('reportSpamJob ', e)
        NotificationManager.error(
          e?.message ||
          "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
          "Error",
          3000
        );
      });
  }

  return (
    <Modal
      open={open}
      onCancel={() => onClose()}
      footer={null}
      closeIcon={null}
      centered
      width={360}
      title={'Report Spam'}
      className="w-full  rounded-[24px]"
    >
      <div className=" ">
        <textarea placeholder={'Suspect this is scam'} value={message} onChange={(e) => setMessage(e.target.value)}
          className="mt-6 w-full rounded p-3 min-h-[42px] border border-[#999] font-medium text-[14px] text-[#000] leading-[19px] bg-[#f5f5f5]"
        >
        </textarea>
        <div className="mt-5 w-full flex justify-center items-center gap-6">
          <Button className="h-[42px] px-6 rounded-full bg-primary2 text-white" loading={loading} onClick={onSubmit}>
            Send
          </Button>
          <Button className="h-[42px] px-6 rounded-full bg-[#333] text-white" onClick={() => onClose()}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default JobSpamReportModal;


