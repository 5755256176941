import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/auth";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { FiArrowLeft, FiChevronRight } from "react-icons/fi";
import { setDarkMode } from "../../redux/actions/app";
import AreYouSureModal from "../../components/AreYouSureModal";
import { useState } from "react";

const Settings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isDarkMode } = useSelector(state => state.app)
    const [showConfirmLogout, setShowConfirmLogout] = useState(false);
    const [showConfirmDel, setShowConfirmDel] = useState(false);

    const onLogout = () => {
        dispatch(logout())
            .then((data) => {
                // NotificationManager.success(
                //     data?.message || "Logout Success",
                //     "Success",
                //     5000
                // );
            })
            .catch((error) => {
                NotificationManager.error(
                    error?.message ||
                    "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                    "Error",
                    3000
                );
            });
    };

    const onDeleteAccount = () => {
        dispatch(logout())
            .then((data) => {
                NotificationManager.success(
                    data?.message || "Delete account Success",
                    "Success",
                    5000
                );
                navigate(`/login`);
            })
            .catch((error) => {
                NotificationManager.error(
                    error?.message ||
                    "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                    "Error",
                    3000
                );
            });
    };

    return (
        <div className='w-full h-full   bg-white-primary1 flex flex-col justify-center items-center'>
            <div className="w-full flex justify-start items-center  py-4  bg-white-primary1 drop-shadow">
                <div className="w-[50px] flex justify-center items-center">
                    <FiArrowLeft size={20} className='text-primary1-white'  onClick={() => navigate(-1)} />
                </div>
                <div className=" flex-1 text-center text-[16px] text-primary1-white font-bold ml-3">Settings</div>
                <div className="w-[50px] flex justify-center items-center"></div>
            </div>
            <div className='mt-6 flex-1 w-full flex flex-col px-5 justify-start items-center'>
                <div className="w-full flex justify-center items-center">
                    <div className="flex-1">
                        <p className="text-[15px] text-primary1-white font-semibold">Language</p>
                        <p className="mt-1 text-[12px] text-black2-gray3">Default language</p>
                    </div>
                    <FiChevronRight size={20} color={'#bbb'} />
                </div>
                <div className="w-full flex justify-center items-center mt-5"
                    onClick={() => dispatch(setDarkMode(!isDarkMode))}
                >
                    <div className="flex-1">
                        <p className="text-[15px] text-primary1-white font-semibold">Night mode</p>
                        <p className="mt-1 text-[12px] text-black2-gray3">{isDarkMode ? 'On' : 'Off'}</p>
                    </div>
                    <FiChevronRight size={20} color={'#bbb'} />
                </div>
                <div className=" cursor-pointer mt-5 w-full text-[15px] text-primary1-white font-semibold"
                    onClick={() => setShowConfirmLogout(true)}
                >
                    Log out
                </div>
                <div className=" cursor-pointer mt-5 w-full text-[15px] text-[#f00] font-semibold"
                    onClick={() => setShowConfirmDel(true)}
                >
                    Delete account
                </div>
            </div>
            {
                showConfirmLogout &&
                <AreYouSureModal
                    title={'Logout'}
                    desc={'Are you sure to logout?'}
                    showModal={showConfirmLogout}
                    onCancel={() => setShowConfirmLogout(false)}
                    onYes={onLogout}
                />
            }
            {
                showConfirmDel &&
                <AreYouSureModal
                    title={'Delete account'}
                    desc={'Are you sure to delete account?'}
                    showModal={showConfirmDel}
                    onCancel={() => setShowConfirmDel(false)}
                    onYes={onDeleteAccount}
                />
            }
        </div>
    );
};

export default Settings;
