import { APP_SET_ACTIVE_BID, APP_SET_ACTIVE_JOB, APP_SET_NEW_JOBS_CNT, SET_DARK_MODE, SET_LANGUAGE, SET_SHOW_SIDEBAR, SET_SHOW_TOP_BTN } from './types';
import {KEYS, getStorageKey, setStorageKey, getLanguage} from '../../utils/storage';
import { setLanguage } from '../../localisations';
import JobService from '../../services/JobService';
import { USER_TYPE } from '../../constants/config';

export const initLanguage = () => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        let lang = getLanguage();
        await setLanguage(lang ?? 'en');
        dispatch({
            type: SET_LANGUAGE, payload: lang ?? 'en'
        });
        resolve();
    });
};

export const initDarkMode = () => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        let isDarkMode = getStorageKey(KEYS.DARK_MODE);
        setStorageKey(KEYS.DARK_MODE, isDarkMode ?? false);
        document.documentElement.classList.toggle('dark', isDarkMode == true);
        dispatch({
            type: SET_DARK_MODE, payload: isDarkMode ?? false
        });
        resolve();
    });
};

export const initCountry = () => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        let countryCode = getStorageKey(KEYS.COUNTRY_CODE);
        setStorageKey(KEYS.COUNTRY_CODE, countryCode ?? 'US');

        // TODO: Do we need to set default language based on country
        // dispatch({
        //     type: SET_LANGUAGE, payload: countryCode ?? 'US'
        // });
        resolve();
    });
};

export const changeLanguage = (lang) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        await setLanguage(lang ?? 'en');
        dispatch({
            type: SET_LANGUAGE, payload: lang ?? 'en'
        });
        resolve();
    });
};

export const setShowTopBtn = (payload) => ({
    type: SET_SHOW_TOP_BTN, payload
})

export const setShowSidebar = (payload) => ({
    type: SET_SHOW_SIDEBAR, payload
})

export const setDarkMode = (payload) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        setStorageKey(KEYS.DARK_MODE, payload);
        document.documentElement.classList.toggle('dark', payload == true);
        dispatch({
            type: SET_DARK_MODE, payload: payload
        });
        resolve();
    });
};

export const getHeroActiveBid = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        const { auth } = getState();
        if (auth.user == null) {
            resolve();
            return;
        }

        JobService.getHeroActiveBid().then((res) => {
            dispatch({
                type: APP_SET_ACTIVE_BID, payload: res.bid
            });
            resolve();
        })
        .catch(err => {
            resolve(err)
        })
    });
};

export const getActiveJob = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        const { auth } = getState();
        if (auth.user == null) {
            resolve();
            return;
        }
        let promise = JobService.getHeroActiveJob();
        if (auth.user?.user_role == USER_TYPE.customer) {
            promise = JobService.getCustomerActiveJob()
        }
        promise.then((res) => {
            dispatch({
                type: APP_SET_ACTIVE_JOB, payload: res.job
            });
            resolve();
        })
        .catch(err => {
            resolve()
        })
    });
};

export const getNewJobsCnt = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        JobService.getNewJobsCnt().then((res) => {
            dispatch({
                type: APP_SET_NEW_JOBS_CNT, cnt: res.cnt, new_job_ids: res.new_job_ids
            });
            resolve();
        })
        .catch(err => {
            resolve()
        })
    });
};