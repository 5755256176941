import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";
import translationEN from "../assets/locales/en.json";
import translationChinese from "../assets/locales/cn.json";
import translationMalay from "../assets/locales/ml.json";
import { KEYS, getStorageKey, setStorageKey } from "../utils/storage";

export const appMoment = moment;

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  cn: {
    translation: translationChinese,
  },
  ml: {
    translation: translationMalay,
  },
};

export const getLanguage = () => {
  return i18n.language;
};

export const setLanguage = async (lang) => {
  await i18n.changeLanguage(lang);
  setStorageKey(KEYS.LANGUAGE, lang);
  appMoment.locale(lang);
};

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  cleanCode: true,
});

export default i18n;
